import type { AbiType, SolidityArrayWithTuple, SolidityTuple } from "abitype";

export const fixedArrayRegexp = /\[\d+\]$/;
export const isArray = (type: string): type is `${AbiType}[]` =>
  type.endsWith("[]");
export const isFixedArray = (type: string): type is `${AbiType}[${number}]` =>
  fixedArrayRegexp.test(type);
export const isTuple = (type: string): type is SolidityTuple =>
  type === "tuple";
export const isArrayOfTuple = (type: string): type is SolidityArrayWithTuple =>
  type.startsWith("tuple[");
