const DEV = false;

import type { Connector } from "@wagmi/connectors";
// import { CoinbaseWalletConnector } from "@wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "@wagmi/connectors/injected";
import { MetaMaskConnector } from "@wagmi/connectors/metaMask";
import { MockConnector } from "@wagmi/connectors/mock";
import {
  createWalletClient,
  custom,
  getAddress,
  hexToNumber,
  http,
  toHex,
  type TransactionReceipt,
  type WalletClient
} from "viem";
import { generatePrivateKey, privateKeyToAccount } from "viem/accounts";
import { mainnet } from "viem/chains";

import {
  Sheet,
  SheetProxy,
  collector,
  logger,
  uncellify,
  type AnyCell,
  type MapCell,
  type ValueCell
} from "@okcontract/cells";
import {
  AllChainsQuery,
  ChainQuery,
  UserQuery,
  type ForeignAccount,
  type User
} from "@okcontract/coredata";
import {
  Environment,
  isEqual,
  newTypeScheme,
  typeNumber,
  type Rational
} from "@okcontract/lambdascript";
import {
  Address,
  LocalRPCSubscriber,
  MultiChainRPC,
  defaultRPCOptions,
  nullAddr,
  type Chain,
  type ChainType,
  type Network
} from "@okcontract/multichain";
import {
  ADMIN,
  APIUser,
  AuthClient,
  GUEST,
  TimeSync,
  cookie,
  getRole,
  parseJwt,
  type UserAuth,
  type UserWallet
} from "@scv/auth";
import {
  APICache,
  GlobalSubscriber,
  LocalSubscriber,
  QueryCache,
  convertToAddressesAndRationals,
  convertToNativeAddressesAndBigInt
} from "@scv/cache";
import wasm from "@scv/libcrypto";
import { hash_to_base64 } from "@scv/utils";

import { findObjectValue } from "./cellObject";
import {
  LOGGED,
  UNLOGGED,
  VERIFYING,
  WIDGET_APP,
  type AppIDs,
  type LogState
} from "./constants";
import type { CoreExecution, WalletDetail } from "./coreExecution.types";
import { DefaultContracts } from "./defaultContract";
import { EnvKeySlippage, EnvKeyValidity } from "./keys";
import { Lighthouse } from "./lighthouse";
import { findName } from "./name";
import { $slippage, $validity } from "./settings";
import type { SentTransaction } from "./tx";
import {
  ALL_CHAINS,
  CODE_REQUIRED,
  LOADING_WALLET,
  detectWallet,
  sign_message,
  type WalletIDType
} from "./wallet";

export const CoreExecutionContextKey = Symbol();

const WIDGET_PRIVATE_KEY = "$priv";

export const METAMASK = "metamask";
export const INJECTED = "injected";
export const COINBASE = "coinbase";
export const LEDGER = "ledger";
export const SAFE = "safe";
export const MOCK = "mock";
export const WALLET_CONNECT = "walletConnect";

export const connectors = {
  [METAMASK]: MetaMaskConnector,
  [INJECTED]: InjectedConnector,
  // [COINBASE]: CoinbaseWalletConnector,
  // @deprecated since Ledger SDK exploit
  // [LEDGER]: LedgerConnector,
  // @todo SafeConnector is crashing bun test
  // [SAFE]: SafeConnector,
  [MOCK]: MockConnector
  // [WALLET_CONNECT]: WalletConnectConnector
} as const;

export type ViemConnector = keyof typeof connectors;

export const createConnector = (connector?: ViemConnector) => {
  // console.log("createConnector", { connector });
  if (!connector) return null;
  let clientConnector: Connector;
  switch (connector) {
    case INJECTED:
    case METAMASK:
      clientConnector = new connectors[connector]();
      break;
    // case COINBASE:
    //   clientConnector = new connectors[connector]({
    //     options: { appName: OKCONTRACT_APP }
    //   });
    //   break;

    // @deprecated since Ledger exploits
    // case LEDGER:
    //   clientConnector = new connectors[connector]({ options: {} });
    //   break;
    // @todo SafeConnector is crashing bun test
    // case SAFE:
    //   clientConnector = new connectors[connector]({ options: {} });
    //   break;
    // case WALLET_CONNECT:
    //   clientConnector = new connectors[connector]({
    //     options: {
    //       projectId: ""
    //     }
    //   });
    //   break;
    default:
      break;
  }

  return clientConnector;
};

export type CoreOptions = {
  connector?: ValueCell<Connector<unknown, unknown>>;
  walletClient?: ValueCell<WalletClient>;
  chain: ChainType;
  sheet: Sheet;
  endpoint: string;
};

export const defaultCoreOptions: CoreOptions = {
  chain: "ethereum",
  sheet: new Sheet(isEqual),
  endpoint: "https://cache.okcontract.com"
};

/**
 * Core is the current Viem-based implementation of Core.
 * @param conn viem Connector
 * @returns
 * @todo use W for Wallet and R for RPC types
 */
export const Core = <C extends Connector<unknown, unknown>>(
  appID: AppIDs = WIDGET_APP, // OKCONTRACT_APP,
  options: CoreOptions = defaultCoreOptions
): CoreExecution<C> => {
  // @todo already done?
  wasm();
  const proxy = new SheetProxy(options.sheet, "Core");
  // Datacache
  const token = proxy.new<string>(undefined, "Core.token");
  const client = proxy.new(new AuthClient(token), "Core.client");
  const apiUser = new APIUser(options.endpoint, appID);
  const apiCache = new APICache(
    client,
    convertToAddressesAndRationals,
    options.endpoint
  );
  const ts = new TimeSync(options.endpoint);
  const cache = new QueryCache(
    new SheetProxy(options.sheet, "cache"),
    apiCache,
    {
      ts
    }
  );
  const global = new GlobalSubscriber(cache);
  const local = new LocalSubscriber(options.sheet, global, proxy, "core");

  const chains = local
    .staticQuery(AllChainsQuery())
    .map(
      (v) =>
        Object.fromEntries(v?.res.map((_ch) => [_ch, local.staticQuery(_ch)])),
      "Core.chains"
    ) as AnyCell<Record<string, AnyCell<Chain>>>;
  const uncellifiedChains = chains.map(
    (ch) => uncellify(ch),
    "uncellifiedChains"
  ) as unknown as MapCell<Record<ChainType, Chain>, false>;
  DEV && logger(uncellifiedChains);
  // @todo we shouldn't uncellify twice :)
  // @todo or reuse uncellifiedChains
  const _chains = chains.map(
    async (_chains) =>
      uncellify(
        Object.fromEntries(
          Object.entries(_chains).map((_chain) => [
            _chain[0].substring(1),
            _chain[1]
          ])
        ),
        {
          getter: (cell) => cell.consolidatedValue
        }
      ),
    "core._chains"
  ) as unknown as AnyCell<{ [key: string]: Chain }>;
  const multi = new MultiChainRPC(
    proxy,
    proxy.new({
      ...defaultRPCOptions(proxy),
      chains: _chains,
      convertToNative: convertToNativeAddressesAndBigInt,
      convertFromNative: convertToAddressesAndRationals
    })
  );

  const wantedWalletAccount = proxy.new<WalletIDType>(
    LOADING_WALLET,
    "Core.wantedWalletAccount"
  );

  const connector =
    options.connector ||
    // @ts-expect-error add coinbase
    proxy.new(createConnector(detectWallet(appID)), "Core.connector");
  connector.subscribe(connectorHandler);
  // listen to provider events
  connector.subscribe(listenToProvider);

  // authentication
  const logState = proxy.new<LogState>(LOADING_WALLET, "Core.logState");
  const isAuth = proxy.map([logState], (st) => st === LOGGED, "Core.isAuth");

  const code = proxy.new<string>(".apifi", "Core.code");
  const connectorAccount = proxy.new<WalletIDType>(
    null,
    "Core.connectorAccount"
  );

  const walletClient: AnyCell<WalletClient> =
    options?.walletClient ||
    proxy.map(
      [connector, wantedWalletAccount],
      async (connector, account) => {
        // console.log({ connector, account });
        if (account === LOADING_WALLET || !account || !connector) return null;
        return createWalletClient({
          account: account.toString() as `0x${string}`,
          transport: custom(await connector.getProvider())
        });
      },
      "Core.walletClient"
    );

  const clientColl = collector<AnyCell<WalletClient>>(proxy);
  const newAccount = async () => {
    const privateKey = cookie.init(
      WIDGET_PRIVATE_KEY,
      generatePrivateKey
    ) as `0x${string}`;
    const privAccount = privateKeyToAccount(privateKey);
    const transport = connector.value
      ? custom(await connector.value.getProvider())
      : http();
    // This client stays "local" to that function so is not used
    // beyond auth.
    return [
      new Address(privAccount.address),
      clientColl(
        proxy.new(
          createWalletClient({
            account: privAccount,
            chain: mainnet,
            transport
          })
        )
      )
    ] as [Address, AnyCell<WalletClient>];
  };
  const verify = async (
    account: string,
    client: AnyCell<WalletClient>,
    code: string = undefined
  ): Promise<UserAuth | typeof CODE_REQUIRED | null> => {
    // 2. Sign
    logState.set(VERIFYING);
    // @todo @security we must find a better derived secret
    const msg = <UserWallet>await apiUser.AuthMessage({ username: account });
    try {
      msg.Sig = await sign_message(client, msg.Msg);
    } catch (error) {
      console.log("verify", { error, account });
      const [acc, cl] = await newAccount();
      // @todo do not loop
      return verify(acc.toString(), cl, code);
    }
    // 3. Auth
    try {
      if (code) msg.code = await hash_to_base64(code);
      const walletAuth = await apiUser.Wallet(msg);
      // We create a derived session key, that should change for each session (ADDR2).
      // @todo @security use libcrypto.KeyPair
      const priv = generatePrivateKey();
      // @todo where does the account come from?
      cookie.set(`${account}.2`, priv);
      cookie.set(account, walletAuth.token);
      token.set(walletAuth.token);
      logState.set(LOGGED);
      return walletAuth;
    } catch (error) {
      console.log("verify", { error, step: 2 });
      logState.set(UNLOGGED);
      if (error instanceof Error && error.message.includes("please wait"))
        return CODE_REQUIRED;
      return null;
    }
  };
  const auth = proxy.map(
    [wantedWalletAccount, code],
    async (account, code, previous: typeof CODE_REQUIRED | UserAuth) => {
      // console.log("auth", { account, code, previous });
      if (account === LOADING_WALLET) return previous;
      // 1. Reuse previous token when available.
      // @todo check expiration
      const _cookie = cookie.get(account?.toString());
      if (_cookie) {
        token.set(_cookie);
        // console.log({ token });
        return { token: token.value, New: false };
      }
      // Create new account and client.
      const [authAccount, client] =
        appID === WIDGET_APP && !account
          ? await newAccount()
          : [account, walletClient];
      return verify(authAccount.toString(), client, code);
    },
    "Core.auth"
  );
  DEV && logger(auth);

  const parsedJWT = proxy.map(
    [wantedWalletAccount, auth],
    (_walletAccount, _auth) =>
      // _auth === AUTHENTICATING
      //   ? AUTHENTICATING
      //   :
      parseJwt(_auth !== CODE_REQUIRED && _auth?.token),
    "Core.parsedJWT"
  );
  // @todo walletID can be null ?
  const walletID = proxy.map(
    [wantedWalletAccount, parsedJWT, walletClient],
    (_wanted, jwt, wc) => {
      return wc?.account
        ? new Address(wc.account.address)
        : _wanted === LOADING_WALLET ||
            // || jwt === AUTHENTICATING
            !_wanted
          ? nullAddr
          : _wanted;
    },
    "Core.walletID"
  ) as AnyCell<Address | undefined | null>;

  const wantedChain = proxy.new<ChainType>(null, "Core.wantedChain");
  const chainId = connector.map(async (c) => {
    try {
      const chainID = await c.getChainId();
      return chainID;
    } catch (error) {
      return null;
    }
  }, "Core.chainId");
  // @todo remove
  chainId.subscribe(async (num) => {
    const m = await uncellifiedChains.get();
    if (m instanceof Error) return;
    const ch = Object.values(m).find(
      (ch) => (ch.numid as unknown as Rational).toNumber() === num
    );
    if (ch) {
      wantedChain.set(ch.id);
      return;
    }
    if (options?.chain !== undefined) wantedChain.set(options.chain);
    // else we wait
    // @todo previously ALL_CHAINS
  });

  // @todo remove?
  const isNoWallet = connector.map(
    (_connector) => !_connector,
    "Core.isNoWallet"
  );
  // @todo remove
  const isShowCode = auth.map(
    (_auth) => _auth === CODE_REQUIRED,
    "Core.isShowCode"
  );
  const ConnectWallet = proxy.map(
    [isNoWallet, wantedWalletAccount],
    (_isNoWallet, _walletAccount) => !_isNoWallet && !_walletAccount,
    "Core.ConnectWallet"
  );
  const currentChain = proxy.map(
    [connector, wantedChain],
    async (connector, wanted) =>
      (connector && wanted && (await switchChain(wanted))) || ALL_CHAINS,
    "Core.currentChain",
    true
  );
  const chain = proxy.map(
    [currentChain, chains],
    (_current, chains) => chains[ChainQuery(_current)] || null,
    "Core.chain"
  ) as AnyCell<Chain>;
  const isSwitchingChain = proxy.map(
    [wantedChain, currentChain],
    (w, c) => w !== c,
    "Core.isSwitchingChain"
  );

  const userID = parsedJWT.map(
    (jwt) =>
      // jwt === AUTHENTICATING ? null :
      jwt?.id || null,
    "Core.userID"
  );
  const userQuery = userID.map(UserQuery, "Core.userQuery");
  const user = local.unwrappedCell(userQuery, "Core.user");
  const userAccount = parsedJWT.map(
    (data) =>
      // data === AUTHENTICATING ? null :
      data?.account || null,
    "Core.userAccount"
  );
  const role = getRole(proxy, token, walletID);
  const defaultContracts = new DefaultContracts(proxy, local);
  const isAdmin = role.map<boolean, true>(
    (role) => role === ADMIN,
    "Core.isAdmin"
  );
  const isGuest = role.map<boolean, true>(
    (role) => role === GUEST,
    "Core.isGuest"
  );

  const rpc = new LocalRPCSubscriber(proxy, multi);
  const ethereum = proxy.new("ethereum", "ethereum");

  const walletAddresses = walletClient
    .map((wc) => wc?.getAddresses() || [], "getAddresses")
    .map(
      (l) =>
        l.map(
          (v) => convertToAddressesAndRationals(v) as unknown as Address<"evm">
        ),
      "walletAddresses"
    );

  const walletAccounts = walletAddresses.map(
    (l, prev: Record<string, WalletDetail>) =>
      Object.fromEntries(
        l
          .map((_addr) => {
            const key = _addr?.toString();
            if (!key) return [undefined, undefined] as [string, WalletDetail];
            if (prev?.[key]) return [key, prev[key]];
            // console.log("walletAccounts", { _addr });
            const addr = proxy.new(_addr, "addr") as unknown as MapCell<
              Address<Network>,
              true
            >;
            // @ts-expect-error @todo @starknet
            const name = findName(rpc, proxy, defaultContracts, ethereum, addr);
            // Token value is not used but to force recomputation of getRole.
            const role = getRole(proxy, token, addr);
            // @todo reimplement by checking the token
            // @todo make sure it's reactive
            const isVerified = role.map(
              (_role) => !!_role,
              "walletAccounts.isVerified"
            );
            // @todo Starknet
            const detail = { name, addr, role, isVerified } as WalletDetail;
            return [key, detail] as [string, WalletDetail];
          })
          .filter(([key, _v]) => key)
      ) as Record<`0x${string}`, WalletDetail>,
    "walletAccounts"
  );

  // @todo move to cells?
  const cellFalse = proxy.new(false, "false") as unknown as MapCell<
    false,
    true
  >;
  const isVerified = proxy.map(
    [walletID, walletAccounts],
    (id, acc) => acc[id?.toString()]?.isVerified || cellFalse
  );
  // @todo redundant with walletID?
  const walletAccount = proxy.map(
    [walletID, walletAccounts],
    (id, acc) => acc[id?.toString()] || null
  );
  const isSwitchingAccount = proxy.map(
    // walletID?
    [wantedWalletAccount, walletAccount],
    (w, c) => {
      // console.log("isSwitchingAccount", { w, c: c.addr.value });
      return w?.toString() !== c?.addr?.value?.toString();
    },
    "Core.isSwitchingAccount"
  );

  const walletName = walletAccount.map(
    (_account) => _account?.name || _account?.addr?.toString() || null,
    "Core.walletName"
  );

  const sentTransactions = proxy.new(
    [] as SentTransaction[],
    "Core.sentTransactions"
  );
  const receipts = proxy.new([] as TransactionReceipt[], "Core.Receipts");
  const settings = new Environment(proxy, { id: "settings" });
  settings.addValueType(
    EnvKeyValidity,
    $validity(proxy),
    newTypeScheme(typeNumber)
  );
  settings.addValueType(
    EnvKeySlippage,
    $slippage(proxy),
    newTypeScheme(typeNumber)
  );

  // Methods

  async function listenToProvider(connector: Connector) {
    if (!connector) return;

    connector.on("disconnect", () => {
      wantedWalletAccount.set(null);
      connectorAccount.set(null);
    });

    const provider = await connector.getProvider();
    if (!provider) throw new Error("empty provider");
    provider.on("chainChanged", (chain: `0x{$string}`) =>
      networkChange(hexToNumber(chain))
    );
    provider.on("accountsChanged", (accounts: `0x{$string}`[]) => {
      // console.log("accountsChanged", { accounts });
      const account = accounts?.[0];
      if (!account) return;

      const addr = new Address(getAddress(account));
      connectorAccount.set(addr);
      try {
        return walletChange([addr]);
      } catch (err) {
        console.log("listenToProvider", { err });
      }
    });
  }
  // @todo merge with listenToProvider?
  async function connectorHandler(_connector: Connector) {
    // console.log("connectorHandler");
    try {
      const acc = await _connector?.getAccount();
      if (!acc) {
        const client = walletClient.value;
        if (client instanceof Error || !client) {
          wantedWalletAccount.set(null);
          return;
        }
        // @security we trust the wallet
        wantedWalletAccount.set(new Address(client.account.address));
        return;
      }
      // const isInLocalStorage = cookie.get(acc);
      wantedWalletAccount.set(new Address(acc));
    } catch (error) {
      // console.log("connectorHandler", { error });
      const wc = walletClient.value;
      if (wc instanceof Error) throw wc;
      const addr = wc?.account?.address;
      wantedWalletAccount.set(addr ? new Address(addr) : null);
      return;
    }
    // @todo move to class
    // await connect();
  }

  /**
   * connect connects a connector and
   * listen for its events
   * @param connector
   * @returns
   */
  const connect = async () => {
    const _connector = await connector.get();
    if (!connector || _connector instanceof Error) return false;

    const res = await _connector.connect();
    if (!res) return false;

    const addr = new Address(res?.account);
    wantedWalletAccount.set(addr);
    connectorAccount.set(addr);

    return true;
  };

  const newLocalSubscriber = (
    onDestroy?: (fn: () => unknown) => void,
    proxy?: SheetProxy,
    name?: string
  ) => {
    const local = new LocalSubscriber(options?.sheet, global, proxy, name);
    if (onDestroy !== undefined) onDestroy(() => local.destroy());
    return local;
  };

  const walletChange = async (accounts: Array<Address>) => {
    if (accounts.length > 0) {
      const walletAccount = await walletID.get();
      if (walletAccount instanceof Error) return;

      const first = accounts[0];
      // no current wallet id, we assign the default account
      if (!walletAccount) {
        wantedWalletAccount.set(first);
        return;
      }

      if (!first || first.equals(walletAccount)) return;
      if (document.visibilityState === "hidden") return;
      // @security we trust the provided wallet
      wantedWalletAccount.set(first);
    }
  };

  /**
   * switchChain switches the current chain, possibly adding the chain if not present.
   * @param ch
   * @see https://docs.metamask.io/guide/rpc-api.html#unrestricted-methods
   */
  const switchChain = async (ch: ChainType): Promise<ChainType> => {
    // @todo replace
    const wanted = (await findObjectValue(
      proxy,
      chains,
      (v: Chain) => v.id === ch
    )?.get()) as Chain;
    if (wanted instanceof Error) throw wanted;
    const wantedChainID = wanted?.numid
      ? (wanted?.numid as unknown as Rational).toBigInt()
      : 1n;
    try {
      const _connector = await connector.get();
      if (!_connector) return;
      const currentChainId = BigInt(await _connector.getChainId());

      if (currentChainId === wantedChainID) return wanted.id;

      const provider = await _connector.getProvider();
      await Promise.all([
        provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: toHex(wantedChainID) }]
        })
      ]);
      return wanted.id;
    } catch (switchError) {
      console.log("switchChain", { switchError });
      const _walletClient = await walletClient.get();
      if (_walletClient instanceof Error) throw _walletClient;
      if (
        // Rabby
        switchError.code === -32603 ||
        switchError.code === 4902 || // MetaMask
        switchError.name === "ChainNotConfiguredForConnectorError"
      ) {
        try {
          let cur = await local.staticQuery(wanted.currency)?.get();
          if (cur instanceof Error || !cur) {
            const id = wanted.currency.replace("tok:", "").toUpperCase();
            cur = {
              id: wanted.currency,
              act: true,
              name: id,
              symbol: id,
              decimals: 18,
              addr: []
            };
          }
          const chain = {
            id: (wanted?.numid as unknown as Rational).toNumber(),
            rpcUrls: { default: { http: wanted.rpc || [] } },
            name: wanted.name,
            nativeCurrency: {
              name: cur.name,
              symbol: cur.symbol,
              decimals: cur.decimals
            },
            blockExplorers: {
              default: { name: "main", url: wanted?.explorer[0] }
            }
          };
          // console.log("addChain", { chain });
          await _walletClient.addChain({ chain });
        } catch (err) {
          // console.log({ err });
          alert(
            `Please add the following Network directly to your wallet:
          Name: ${wanted.name}
          ID: ${toHex((wanted?.numid as unknown as Rational).toBigInt())}
          RPC: ${wanted.rpc}
          Explorer: ${wanted.explorer}`
          );
          wantedChain.set(currentChain.value);
        }
      }
    }
  };

  const disconnect = async () => (await connector.get())?.disconnect();

  const dropAllUsers = async () => {
    const connectedWallets = await walletAccounts.get();
    if (connectedWallets instanceof Error) return;

    for (const [k] of Object.entries(connectedWallets)) cookie.delete(k);

    await disconnect();

    token.set(null);
    logState.set(UNLOGGED);
    wantedWalletAccount.set(null);
    return true;
  };

  const networkChange = async (chainId: number) => {
    const newChain = (await findObjectValue(
      proxy,
      chains,
      (v: Chain) =>
        (v.numid as unknown as Rational).toBigInt() === BigInt(chainId)
    )?.get()) as Chain;
    if (!newChain) {
      console.log("networkChange", { chainId });
      return;
    }
    wantedChain.set(newChain.id);
  };

  const pairForeignAccounts = async (usr: User, fa: ForeignAccount) => {
    const jwt = parsedJWT.value;
    if (jwt instanceof Error) throw jwt;
    const userID =
      // jwt === LOADING_WALLET ? null :
      jwt?.id || null;
    if (!userID) throw new Error("userID not found");

    // console.log("pairForeignAccounts", { usr, fa, userID });
    // discord link already set
    if (usr?.f?.find((fa) => fa.ty === "Dis") !== undefined) return true;

    // generate KeyPair for discordID
    const msg = <UserWallet>await apiUser.AuthMessage({ username: fa.u });
    // sign msg with client
    msg.Sig = await sign_message(walletClient, msg.Msg);

    // call discordAuthVerify
    const walletAuth = await apiUser.VerifyDiscord(token.value, {
      ID: userID,
      Key: fa.u,
      ESM: msg
    });
    return walletAuth !== undefined;
  };

  return {
    AppID: appID,
    Sheet: options.sheet,
    Api: apiCache,
    MultiRPC: multi,
    Cache: cache,
    Global: global,
    CacheOnce: global.once.bind(global),
    Local: newLocalSubscriber,
    LocalRPC: (
      onDestroy?: (fn: () => unknown) => void,
      proxy?: SheetProxy,
      _name?: string
    ) => {
      const local = new LocalRPCSubscriber(proxy, multi);
      if (onDestroy !== undefined) onDestroy(() => local.destroy());
      return local;
    },
    IsSwitchingAccount: isSwitchingAccount,
    IsSwitchingChain: isSwitchingChain,
    WalletID: walletID,
    ConnectedWallets: walletAccounts,
    IsVerified: isVerified,
    IsAuth: isAuth,
    WalletAccount: walletAccount,
    WalletName: walletName,
    WalletClient: walletClient,
    // @ts-expect-error why ?
    Connector: connector,
    WalletChange: walletChange,
    Connect: connect,
    Disconnect: disconnect,
    Chains: chains,
    SwitchChain: switchChain,
    WantedChain: wantedChain,
    CurrentChain: currentChain,
    Chain: chain,
    IsAdmin: isAdmin,
    IsGuest: isGuest,
    Role: role,
    SentTransactions: sentTransactions,
    Receipts: receipts,
    Settings: settings,
    User: user,
    Account: userAccount,
    WantedWalletAccount: wantedWalletAccount,
    IsConnectWallet: ConnectWallet,
    IsNoWallet: isNoWallet,
    Auth: auth,
    IsShowCode: isShowCode,
    ConnectorAccount: connectorAccount,
    Drop: dropAllUsers,
    Code: code,
    Token: token,
    ParsedJWT: parsedJWT,
    LogState: logState,
    DefaultContracts: defaultContracts,
    PairForeignAccount: pairForeignAccounts,
    Lighthouse: new Lighthouse(proxy, walletClient)
  };
};
