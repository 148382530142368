<script lang="ts">
  import { DataEditor, type EditorNode } from "@okcontract/fred";
  import type { Instance } from "@okcontract/sdk";
  import { Button } from "@okcontract/uic";
  import { AlertCellError } from "@scv/dcuic";

  import type { EditorOptions } from "../types";

  import ObjectFieldEdit from "./ObjectFieldEdit.svelte";
  import ObjectLabel from "./ObjectLabel.svelte";

  export let instance: Instance;
  const proxy = instance._proxy;

  export let editor: DataEditor;
  const params = editor?.params;

  // @todo Should be a cell?
  export let node: EditorNode;
  export let parent: EditorNode;

  const definition = node.definition;
  const value = proxy.get(node.value);

  export let options: EditorOptions = {};

  const isHovering = proxy.new(false, "isHovering");

  $: locked = $definition && "locked" in $definition && $definition.locked;
  $: optional =
    $definition && "optional" in $definition && $definition.optional;
  $: isInline =
    ($definition && "inline" in $definition && $definition.inline) || false;
  $: css = ($definition && "css" in $definition && $definition.css) || "";
  // @todo move to @okcontract/fred
  $: innerType =
    $definition && "isColor" in $definition && $definition.isColor
      ? "color"
      : "base" in $definition && $definition.base === "boolean"
        ? "boolean"
        : "isImg" in $definition && $definition.isImg
          ? "image"
          : "object" in $definition && $definition.object
            ? "object"
            : null;

  const deletable =
    value &&
    proxy.map(
      [value, isHovering],
      (_value, _isHovering) => {
        const withOptional = optional ? _value !== null : false;
        return (
          _isHovering &&
          _value !== null &&
          withOptional &&
          !locked &&
          !options?.view
          // @todo not sure
          // value instanceof ValueCell
        );
      },
      "ObjectEditItem.closeBtn"
    );
  $: showLabel =
    !($definition && "hideLabel" in $definition && $definition.hideLabel) &&
    !options.hideLabels &&
    !($definition && "dict" in $definition && Object.keys($value).length === 0);

  $: customCss =
    options?.view && "cssView" in $definition
      ? $definition.cssView
      : "css" in $definition
        ? $definition.css
        : "relative";
</script>

{#if $definition instanceof Error}
  <AlertCellError cell={definition} />
{:else if $definition && !($definition && "hidden" in $definition && $definition.hidden) && $value !== undefined && $value !== null}
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- ${innerType !== "object" && isHovering && !isInline ? "" : ""}
        class={`${isInline && isTh ? "basis-1/5" : "basis-1"} ${
      isTh && innerType === "image" ? "grow pl-5" : ""
    } `}
    class={"css" in $definition
      ? $definition.css
      : options?.view
        ? "cssView" in $definition
          ? $definition.cssView
          : ""
        : "grid overflow-visible gap-2"}
  -->
  <div
    class={customCss}
    on:mouseenter={(ev) => {
      if (!params?.highlightOnHover) return;
      $isHovering = true;
    }}
    on:mouseleave={(ev) => {
      if (!params?.highlightOnHover) return;
      $isHovering = false;
    }}
  >
    <!--{innerType === 'object' ? '' : ''}
    <div
      class="{innerType === 'color' ? ' items-center' : ''}{innerType ===
      'image'
        ? ' bottom-3'
        : ''}"
    >-->
    <!-- {node} -->
    <ObjectLabel
      display={params?.showLabels || true}
      {showLabel}
      showHint={params?.hintText || true}
      def={$definition}
      itemHover={isHovering}
      highlightOnHover={params?.highlightOnHover || false}
      {options}
    ></ObjectLabel>
    <ObjectFieldEdit {instance} {node} {editor} {options} />
    {#if deletable && $deletable}
      <!-- {@const _ = console.log({ parent, node, $definition })} -->
      <div class="absolute top-0 right-0">
        <Button
          style="ghost"
          size="xs"
          label={"array" in $definition && $definition.array
            ? "Delete section"
            : ""}
          iconPrepend={true}
          icon={"array" in $definition && $definition.array
            ? "remove-o"
            : "close"}
          disabled={params.disabled}
          action={() =>
            editor.removeProperty(
              parent,
              node.key || node.path[node.path.length - 1]
            )}
        />
      </div>
    {/if}
  </div>
{/if}
