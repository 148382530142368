// @todo remove?
export const ABIXKey = "#abix";

import type { AbiFunction } from "abitype";
import { getFunctionSignature, type Abi } from "viem";

import {
  ABIMethodAdm,
  ABIMethodAllowances,
  ABIMethodButton,
  ABIMethodImage,
  ABIMethodInfo,
  ABIMethodIntent,
  ABIMethodPre,
  ABIMethodRcpt,
  ABIMethodTitle,
  ABIValueTypes,
  isABINumber,
  type ABIMethod,
  type ABIMethodFields,
  type ABIValueType,
  type ABIValues
} from "@okcontract/coredata";

import {
  initialValue,
  reduce,
  type AnyCell,
  type Cellified,
  type SheetProxy,
  type ValueCell
} from "@okcontract/cells";
import {
  OKWidgetCallStep,
  OKWidgetNetworkTXStep,
  OKWidgetSigStep,
  type ABIExtra,
  type OKWidgetStep,
  type OKWidgetStepType,
  type SmartContract
} from "@okcontract/coredata";
import {
  VIEW,
  objectDefinition,
  type EditorMode,
  type EditorNode,
  type GroupDefinition,
  type LabelledTypeDefinition,
  type MapTypeDefinitions,
  type TypeScheme
} from "@okcontract/fred";
import type { Environment } from "@okcontract/lambdascript";
import { mergeObjects } from "@scv/utils";

import { abixOrId } from "./abix";
import type { Instance } from "./instance";
import type { Step } from "./steps";
import {
  ThemeDarkPropertyDefinition,
  ThemePropertyDefinition,
  themeDefinition,
  themeDefinitions
} from "./theme";

const filterStepDefinition = (step: OKWidgetStep<OKWidgetStepType>) => {
  switch (step.sty) {
    case OKWidgetCallStep: {
      const { ntx, fch, sig, ...newStep } = step;
      return newStep;
    }
    case OKWidgetSigStep: {
      const { ntx, fch, m, q, ...newStep } = step;
      return newStep;
    }
    case OKWidgetNetworkTXStep: {
      const { m, q, sig, ...newStep } = step;
      return newStep;
    }
    default:
      return step;
  }
};

const uniqueMethods = (abi: Abi) => {
  if (!abi) return;
  // select non-constant methods
  const m = abi.filter(
    (abiFunc) =>
      abiFunc?.type === "function" &&
      (abiFunc.stateMutability === "payable" ||
        abiFunc.stateMutability === "nonpayable")
  ) as AbiFunction[];
  // count overloaded methods
  const count = m.reduce(
    (acc, mi) => {
      const name = mi.name; // short name
      return acc.set(name, (acc.get(name) || 0) + 1);
    },
    new Map() as Map<string, number>
  );

  // return short name if not ambiguous
  return m.map((abiFunc) =>
    count.get(abiFunc.name) === 1 ? abiFunc.name : getFunctionSignature(abiFunc)
  );
};

/**
 * okayedMethod checks if method m is okayed in ABIExtra.
 * @param x
 * @returns
 */
export const okayedMethod = (x: ABIExtra) => (m: string) =>
  x?.methods ? Object.keys(x.methods).includes(m) : false;

/**
 * methods_ok_for_widget computes the list of okayed methods for a widget (possibly none).
 * @param methods
 * @param x
 */
export const okayedMethods = (methods: string[], x: ABIExtra) =>
  x ? methods.filter(okayedMethod(x)) : methods;

/**
 * getMethods returns an array of allowed methods
 * @param abi
 * @param abix
 * @param m
 * @returns
 */
const getMethods = (
  abi: Abi,
  is_admin = false,
  abix?: ABIExtra,
  m?: string
) => {
  const methods = (abi && uniqueMethods(abi)) || [];
  return [
    ...((is_admin ? methods : okayedMethods(methods, abix)) || []),
    ...((m && [m]) || []) // cloned widget method if not already included
  ];
};

/**
 * getMethodDefinition returns the definition for contract call method.
 * @param core
 * @param step
 * @returns
 */
const getMethodDefinition = (instance: Instance) => (node: EditorNode) => {
  const proxy = instance._proxy;
  if (!node?.parent) return proxy.new({ base: "string", label: "Method" });
  const stepCell = proxy.get(node.parent) as Cellified<Step<OKWidgetStepType>>;
  const sty = proxy.map([stepCell], (_s) => _s?.sty || null);
  const q = proxy.map([stepCell], (_s) => _s?.q || null);

  const contract = instance._local.unwrappedCell(q) as AnyCell<SmartContract>;
  // @todo reuse prev
  const abixes = proxy.map(
    [contract],
    (_c) =>
      _c?.xr?.map((_xr) => instance._local.staticQuery(abixOrId(_xr))) || []
  );
  const merged = reduce(
    proxy,
    abixes,
    (acc, elt) => mergeObjects(acc, elt),
    {} as ABIExtra
  );
  const addr = proxy.map(
    [contract, instance.wantedChain],
    (_c, _ch) =>
      _c?.addr?.find((_addr) => _addr.chain === _ch) || _c?.addr?.[0] || null
  );
  const abi = instance._getABI(addr);
  const methods = proxy.map(
    [abi, instance._core.IsAdmin, merged],
    (_abi, _isAdmin, _abix) => getMethods(_abi.parsed, _isAdmin, _abix)
  );

  const defaultLTD = proxy.map([sty, q], (_sty, _q) => ({
    hidden: _sty !== OKWidgetCallStep || !_q,
    label: "Method",
    enum: []
  }));
  const output = proxy.map([defaultLTD, methods], (_ltd, _methods) => ({
    ..._ltd,
    enum: _methods,
    default: _methods?.[0]
  }));
  return initialValue(proxy, defaultLTD, output);
};

export const widgetGroups = (proxy: SheetProxy): AnyCell<GroupDefinition>[] => [
  proxy.new({ id: "main", l: "Interaction Information" }),
  proxy.new({ id: "steps", l: "Interaction Specification" }),
  proxy.new({ id: "par", l: "Parameters" }),
  proxy.new({ id: "custom", l: "Customization" }),
  proxy.new({ id: "image", l: "Theme & Connection Screen" })
];

export const key_group_collapsed = (k) => `gr.${k}.clp`;

const OKWidgetStepDefinition =
  (instance: Instance, mode: EditorMode) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> =>
    objectDefinition(
      instance._proxy,
      {
        sty: {
          label: "Step Type",
          hint: "Type of action performed by this widget step",
          enum: {
            [OKWidgetCallStep]: "Call a contract",
            [OKWidgetSigStep]: "Sign a message",
            [OKWidgetNetworkTXStep]:
              "Generate a transaction from OKcontract nodes"
          },
          recomp: true
        },
        org: {
          label: "Organization for this step",
          hint: "This will replace contract logo in the header",
          base: "string",
          search: "org",
          optional: true,
          icon: "office-o"
        },
        q: (node: EditorNode) => {
          if (!node?.parent)
            return instance._proxy.new({ label: "Contract", base: "string" });
          const stepCell = instance._proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance._proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetCallStep,
            label: "Contract",
            base: "string",
            search: "contract",
            locked: mode === VIEW
          }));
        },
        m: getMethodDefinition(instance),
        sig: (node: EditorNode) => {
          if (!node?.parent)
            return instance._proxy.new({ label: "Signature", base: "string" });
          const stepCell = instance._proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance._proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetSigStep,
            label: "Signature",
            hint: "Expression that must be signed by the user",
            base: "string",
            isExpr: true,
            icon: "signature"
          }));
        },
        ntx: (node: EditorNode) => {
          if (!node?.parent)
            return instance._proxy.new({ label: "Signature", base: "string" });
          const stepCell = instance._proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance._proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetNetworkTXStep,
            label: "Network expression",
            base: "string",
            isExpr: true
          }));
        },
        fch: (node: EditorNode) => {
          if (!node?.parent)
            return instance._proxy.new({
              label: "Available on the following chains",
              array: () =>
                instance._proxy.new({
                  base: "string",
                  label: "Chain",
                  enum: []
                }),
              unique: true
            });
          const stepCell = instance._proxy.get(node.parent) as Cellified<
            Step<OKWidgetStepType>
          >;
          const sty = stepCell.value.sty;
          return instance._proxy.map([sty], (_sty) => ({
            hidden: _sty !== OKWidgetNetworkTXStep,
            label: "Available on the following chains",
            // @todo chain_names enum
            array: () =>
              instance._proxy.new({ base: "string", label: "Chain", enum: [] }),
            // @todo unique for enum should remove existing entries from enum options
            unique: true
          }));
        },
        xm: {
          label: "",
          name: "ABIMethod"
        },
        skip: (_node: EditorNode) =>
          instance._proxy.new({
            label: "Skip the step if...",
            help: "Expression that defines whether the step should be skipped",
            base: "string",
            isExpr: true,
            optional: true,
            icon: "hand-o"
          })
      },
      "Interaction step: Transaction or Signature",
      {
        // @todo check always ValueCell
        lens: (v: ValueCell<unknown>) => {
          const conv = instance._proxy.new(undefined);
          v.subscribe((_v: OKWidgetStep<OKWidgetStepType>) => {
            conv.set(filterStepDefinition(_v));
          });
          conv.subscribe((_v) => {
            v.set(_v);
          });
          return conv;
        }
      }
    );

/**
 * TypeScheme definitions for Widget.
 * @param cons
 * @returns
 * @todo flatten without OKWidget
 */
export const widgetTypeScheme = (
  instance: Instance,
  mode: EditorMode
): MapTypeDefinitions => ({
  OKWidget: () =>
    objectDefinition(
      instance._proxy,
      {
        id: {
          label: "Unique ID",
          base: "string",
          min: 3,
          hidden: true,
          gr: "main"
        },
        ver: {
          label: "Version",
          base: "number",
          def: 1,
          hidden: true,
          gr: "main"
        },
        name: {
          label: "Interaction name",
          pl: "ex. Swap WETH to USDC on Uniswap",
          base: "string",
          min: 5,
          gr: "main"
        },
        act: {
          label: "Active",
          base: "boolean",
          locked: true,
          gr: "main",
          hidden: true
        },
        own: {
          label: "Owner",
          base: "string",
          isAddress: true,
          locked: true,
          gr: "main"
        },
        qri: {
          label: "Attestation Reference ID",
          base: "string",
          gr: "main",
          hidden: true
        },
        org: {
          // FIXME: choose verified org
          label: "Organization",
          base: "string",
          hidden: true,
          optional: true,
          gr: "main",
          icon: "office-o"
        },
        st: {
          label: "Steps", // "List of transactions"
          array: () => instance._proxy.new({ name: "OKWidgetStep" }),
          showAsTabs: (i) => `Step ${i + 1}`,
          min: 1,
          gr: "steps",
          hideLabel: true
        },
        ...themeDefinitions(instance._proxy)
      },
      "Onchain Interaction"
    ),
  OKWidgetStep: OKWidgetStepDefinition(instance, mode),
  ABIMethod: ABIMethodRef(instance._proxy),
  ABIMethodFields: ABIMethodFieldsRef(instance._proxy),
  ABIValues: ABIValuesRef(instance._proxy),
  ...themeDefinition(instance._proxy),
  // FIXME: compile to bytecode here...
  ThemeDarkProperty: ThemeDarkPropertyDefinition(instance._proxy),
  ThemeProperty: ThemePropertyDefinition(instance)
});

export const WidgetTypeDefinition = (): LabelledTypeDefinition => ({
  label: "OKWidget",
  name: "OKWidget"
});

export const widgetSchema = (
  instance: Instance,
  mode: EditorMode
): TypeScheme => ({
  types: instance._proxy.new(widgetTypeScheme(instance, mode)),
  values: instance._proxy.new(WidgetTypeDefinition()),
  gs: instance._proxy.new(widgetGroups(instance._proxy))
});

export const LoadingDefinition: LabelledTypeDefinition = {
  label: "Loading",
  pl: "Loading...",
  base: "string",
  isLoader: true
};

export const ABIMethodRef =
  (proxy: SheetProxy) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> =>
    objectDefinition(
      proxy,
      {
        fields: { label: "", name: "ABIMethodFields" },
        values: (node: EditorNode) => {
          if (!node) return proxy.new({ label: "Values" });
          const valuesCell = proxy.get(node.value) as Cellified<ABIMethod>;
          return valuesCell.map((_v) => ({
            label: "Values",
            name: "ABIValues",
            hidden: !Object.keys(_v)?.length
          }));
        }
      },
      "ABIMethod",
      {
        def: {},
        hideLabel: true,
        lens: (v: ValueCell<unknown>) => {
          const fields: Cellified<ABIMethodFields> = proxy.new({}, "lens:org");
          const values: Cellified<ABIValues> = proxy.new({}, "lens:name");

          v.subscribe((_v) => {
            const entries = Object.entries(_v);
            for (const [key, value] of entries) {
              const target = key.startsWith("@") ? "fields" : "values";
              const cell = target === "fields" ? fields : values;
              cell.update((_cell) => {
                return { ..._cell, [key]: value };
              });
            }
          });
          const obj = proxy.map(
            [fields, values],
            () => ({ fields, values }),
            "lens:obj"
          );

          obj.subscribe(async (_obj) => {
            if (_obj instanceof Error) throw new Error(`lens failed: ${_obj}`);
            const result = {};
            const fields = _obj.fields.value;
            const values = _obj.values.value;
            Object.assign(result, fields ?? {});
            Object.assign(result, values ?? {});
            const nv = Object.keys(result).length ? result : {};
            // @todo
            v.set(nv);
          });
          // console.log("ABIMethod lens", { obj });
          return obj;
        }
      }
    );

export const ABIMethodFieldsRef =
  (proxy: SheetProxy) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> => {
    const element = proxy.new({
      label: "Text or expression",
      base: "string",
      isExpr: true,
      compact: true,
      def: '""'
    } as LabelledTypeDefinition);
    return objectDefinition(
      proxy,
      {
        [ABIMethodAllowances]: {
          label: "Allowances",
          icon: "dollar-o",
          optional: true,
          dict: (k) =>
            proxy.new({
              label: "",
              base: "string",
              isExpr: true,
              compact: true
            }),
          gr: "par"
        },
        [ABIMethodPre]: {
          label: "Pre-Conditions",
          icon: "code-bracket-o",
          array: () =>
            proxy.new({
              label: "Pre-condition",
              base: "string",
              isExpr: true,
              add: true,
              compact: true
            }),
          optional: true,
          gr: "par"
        },
        [ABIMethodTitle]: {
          label: "Title elements",
          icon: "tag-o",
          array: () => element,
          optional: true,
          inline: true,
          gr: "custom"
        },
        [ABIMethodInfo]: {
          label: "Information text",
          icon: "bars-left-o",
          array: () => element,
          optional: true,
          inline: true,
          gr: "custom"
        },
        [ABIMethodButton]: {
          label: "Button name",
          array: () => element,
          min: 1,
          max: 1,
          optional: true,
          inline: true,
          gr: "custom"
        },
        [ABIMethodImage]: {
          label: "Image",
          hint: "url, IPFS url or expression",
          icon: "image",
          base: "string",
          isExpr: true,
          isImg: true,
          optional: true,
          compact: true,
          gr: "custom"
        },
        [ABIMethodRcpt]: {
          label: "Recipients",
          icon: "users-o",
          array: () => element,
          optional: true,
          hidden: true,
          gr: "custom"
        },
        [ABIMethodAdm]: {
          label: "Admin reserved",
          icon: "user-c-o",
          base: "boolean",
          optional: true,
          gr: "custom"
        },
        [ABIMethodIntent]: objectDefinition(
          proxy,
          {
            n: {
              label: "Name",
              base: "string"
            },
            p: {
              label: "Parameters",
              dict: (k) =>
                proxy.new({
                  label: "",
                  base: "string",
                  isExpr: true
                })
            }
          },
          "Intent",
          {
            icon: "edit-alt-o",
            optional: true,
            hidden: true,
            gr: "par"
          }
        )
      },
      "ABIMethodFields"
    );
  };

const SEARCH_LIST = [
  "token-chain",
  "token",
  "nft",
  "contract",
  "swap",
  "stake"
];
export const ABIValuesRef =
  (proxy: SheetProxy) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> =>
    proxy.new({
      label: "ABIValues",
      compact: true,
      dict: (node: EditorNode) =>
        objectDefinition(
          proxy,
          {
            v: (node: EditorNode, env: Environment) => {
              const nullCell = proxy.new(null);
              const parent =
                node?.parent &&
                (proxy.get(node.parent).value as {
                  v: AnyCell<string>;
                  ty: AnyCell<string>;
                });
              const ty = parent?.ty?.value || undefined;
              if (ty instanceof Error) return nullCell;
              const search = ty
                ? parent.ty.map((_ty) =>
                    SEARCH_LIST.includes(_ty) ? _ty : null
                  )
                : nullCell;
              return search.map((_search) => ({
                label: "Expression",
                icon: "formula",
                base: "string",
                isExpr: true,
                def: "",
                optional: true,
                search: _search || undefined,
                compact: true
              }));
            },
            ty: {
              label: "Type",
              icon: "variable-o",
              // @todo labels
              enum: ABIValueTypes,
              lens: (v: ValueCell<ABIValueType>) => {
                const type: ValueCell<ABIValueType> = proxy.new(undefined);
                v.subscribe((_v) => {
                  type.set(isABINumber(_v) ? "number" : _v);
                });
                type.subscribe((_type) => {
                  v.set(_type);
                });
                return type;
              },
              optional: true,
              cssView: "badge badge-secondary badge-outline"
            },
            l: {
              label: "Label",
              icon: "tag-o",
              base: "string",
              optional: true,
              compact: true
            },
            em: {
              label: "Enum",
              icon: "hashtag-o",
              base: "string",
              isExpr: true,
              optional: true,
              compact: true
            },
            desc: {
              label: "Description",
              icon: "bars-left-o",
              base: "string",
              optional: true,
              compact: true
            },
            pl: {
              label: "Placeholder",
              icon: "text",
              base: "string",
              optional: true
            },
            a: {
              label: "Amount definition",
              icon: "number",
              optional: true,
              base: "string",
              isExpr: true,
              compact: true
            },
            def: {
              label: "Default",
              icon: "code-bracket-o",
              optional: true,
              base: "string",
              isExpr: true
            },
            min: {
              label: "Minimum value",
              icon: "minus-c-o",
              optional: true,
              base: "string",
              isExpr: true,
              compact: true
            },
            max: {
              label: "Maximum value",
              icon: "plus-c-o",
              optional: true,
              base: "string",
              isExpr: true,
              compact: true
            },
            x: {
              label: "Allow external definition",
              icon: "check-c-o",
              optional: true,
              base: "boolean",
              compact: true
            },
            opt: {
              label: "Optional definition",
              icon: "check-c-o",
              optional: true,
              base: "boolean",
              compact: true
            },
            dec: {
              label: "Optional decimals",
              icon: "number",
              optional: true,
              base: "number",
              compact: true
            }
          },
          "ABIValue",
          { cssView: "flex" }
        )
    });
