import { Sheet, type ValueCell } from "@okcontract/cells";
import { isEqual } from "@okcontract/lambdascript";
import type { UIDefinition } from "@scv/dcuic";

import * as Config from "../../config.json";

/**
 * @todo this should only be used for `config_store` and `current_mode`.
 **/
const topLevelSheet = new Sheet(isEqual);

// Environment values (constant keys)
// We need to ensure all values differ.

export const FRONT_URL = "f";
export const WALLET_PUBKEY = "p";
export const WIDGET_URL = "u";
export const UNLOGGED = "l";
export const LOGGED = "h";

export const ENDPOINT = Config.Cache;

export const WIDGET_APP = "widget";
export const OKCONTRACT_APP = "apifi"; // cspell:disable-line
export const INTERACT_APP = "interact";
export const DISCORD_APP = "discord";
export const TEST_APP = "test";

export type AppIDs =
  | typeof WIDGET_APP
  | typeof OKCONTRACT_APP
  | typeof TEST_APP
  | typeof INTERACT_APP
  | typeof DISCORD_APP;

/**
 * AppDefinition defines an app.
 *
 * Should be set by the first index.ts file.
 **/
export interface AppDefinition {
  /** AppID for the service */
  AppID: AppIDs;
  /** service URL */
  Endpoint?: string;
  /** Password login */
  Password?: boolean;
  /** Metamask login */
  Metamask: boolean;
  /** Guest required */
  Guest: boolean;
  /** UI definition */
  UI: UIDefinition;
  /** Constant values */
  Env: unknown;
}

/**
 * config_store is the main config store.
 **/
export const config_store: ValueCell<AppDefinition> = topLevelSheet.new(
  undefined,
  undefined,
  "config_store"
);

// FIXME: current mode should update from location
export const current_mode: ValueCell<string> = topLevelSheet.new(
  "Home",
  undefined,
  "current_mode"
);

/**
 * DatacacheCode is a key holding the datacache code.
 */
export const DatacacheCode = "c";
