import type { SheetProxy } from "@okcontract/cells";
import { objectDefinition, type MapTypeDefinitions } from "@okcontract/fred";
import { Rational } from "@okcontract/lambdascript";

export const $validity = (proxy: SheetProxy) =>
  proxy.new(new Rational(60), "settings.$validity");
export const $slippage = (proxy: SheetProxy) =>
  proxy.new(new Rational(1), "settings.$slippage");

export const settingsTypescheme = (proxy: SheetProxy): MapTypeDefinitions => ({
  settings: () => settingsSchema(proxy)
});

export const settingsSchema = (proxy: SheetProxy) =>
  objectDefinition(
    proxy,
    {
      $validity: {
        label: "Transaction deadline",
        pl: "60",
        base: "number",
        min: new Rational(0),
        unit: "min"
        // step: 5,
      },
      $slippage: {
        label: "Slippage",
        pl: "0.5",
        base: "number",
        min: new Rational(0),
        max: new Rational(100),
        unit: "%",
        step: 0.1
      }
    },
    "Widget settings"
  );
