<script lang="ts">
  import type { ValueCell } from "@okcontract/cells";
  import { AlertBanner, Icon } from "@okcontract/uic";
  import { config_store, detectDevice, FRONT_URL } from "@scv/app";
  import { Logo } from "@scv/dcuic";

  export let isOpen: ValueCell<boolean>;
  let condition = true;
  //const theme = getTheme();
  //const compiledTheme = theme?.compiled;
</script>

<!-- if no wallet -->

<div class="bg-base-100 text-base-content">
  <h3 class="text-lg font-bold">Connect a Wallet</h3>
  {#await detectDevice() then device}
    {#if device.includes("mobile")}
      <AlertBanner>
        You're using a mobile device. Best is to open <b
          >{$config_store.Env[FRONT_URL]}</b
        > directly in your wallet browser.
      </AlertBanner>
    {/if}
  {/await}
  <ul class="menu px-0">
    <li>
      <a href="https://metamask.io/" target="_blank" rel="noreferrer">
        <Logo logo="metamask" size="md" /><span>MetaMask</span>
        <div class="btn btn-sm btn-primary btn-outline">Get</div>
      </a>
    </li>
    <li>
      <a href="https://rabby.io/" target="_blank" rel="noreferrer">
        <Logo logo="rabbywallet" size="md" /><span>Rabby</span>
        <div class="btn btn-sm btn-primary btn-outline">Get</div>
      </a>
    </li>
    <li>
      <a href="https://www.argent.xyz/" target="_blank" rel="noreferrer">
        <Logo logo="argentwallet" size="md" /><span>Argent</span>
        <div class="badge badge-warning">Coming soon</div>
      </a>
    </li>
    <li>
      <a href="https://www.ledger.com/" target="_blank" rel="noreferrer">
        <Logo logo="ledgerwallet" size="md" /><span>Ledger</span>
        <div class="badge badge-warning">Coming soon</div>
      </a>
    </li>
  </ul>

  <a
    href="https://ethereum.org/en/wallets/find-wallet/"
    target="_blank"
    rel="noreferrer"
    class="p-2 link link-info flex gap-2 items-center justify-center"
  >
    Explore more wallets <Icon name="external" size="xs" /></a
  >
</div>
