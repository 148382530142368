import { getAddress, recoverMessageAddress } from "viem";

// @todo cycle?
import type {
  CachedData,
  DataCacheType,
  EVMToken,
  OKWidget,
  Organization,
  SmartContract
} from "@okcontract/coredata";
import { Address, nullAddr } from "@okcontract/multichain";

import { convertToNativeAddressesAndBigInt } from "./converter";
import { write_message } from "./write";

// @todo we should deploy a contract on Polygon to write that data, and read through RPC
export const signers = {
  "0x66881F603f71268b1bEc29D492FE9BC13B45eab2": "node #1",
  "0x7c7F4b9F2fa3b0617e9F40720a9B7b545af95c7d": "node #H",
  "0x886d5BE42a77FC4f960384F6F25B56294174c6ae": "node #C"
};

// authorized is the list of authorized address to sign
export const authorized = Object.keys(signers).map(getAddress);

// @todo move to coredata
export const signerOf = <T extends DataCacheType>(cd: CachedData<T>) => {
  switch (cd.ty) {
    case "widget":
      // @todo type annotation should not be required
      return (cd.data as OKWidget)?.own;
    case "contract":
      return (cd.data as SmartContract)?.from;
    case "token":
      return (cd.data as EVMToken)?.from;
    case "org":
      return (cd.data as Organization)?.from;
  }
  return nullAddr;
};

/**
 * verifySignature of a CachedData.
 * @param core
 * @param cd
 * @param signature
 * @returns
 */
export const verifySignature = async <T extends DataCacheType>(
  cd: CachedData<T>,
  signature: `0x${string}` = cd.sig
) => {
  // need to convert to native bc initially sign with it
  const convert = convertToNativeAddressesAndBigInt(cd.data);
  // @ts-ignore @todo write Converted type function
  const message = write_message(cd.ty, convert);
  const verify = await recoverMessageAddress({ message, signature });
  const _signerOf = signerOf(cd);
  const signer = _signerOf
    ? getAddress(
        _signerOf instanceof Address ? _signerOf.toString() : _signerOf
      )
    : undefined;
  return (
    verify === signer || authorized.find((v) => verify === v) !== undefined
  );
};
