import { getAbiItem, parseAbi, type Abi } from "viem";

import type { AnyCell, MapCell } from "@okcontract/cells";
import type { ABI, ABIExtra, CacheQuery } from "@okcontract/coredata";
import type { LocalRPCSubscriber } from "@okcontract/multichain";
import {
  ContractType,
  type Address,
  type ChainType,
  type EVMAddress
} from "@okcontract/multichain";
import type { LocalSubscriber } from "@scv/cache";

import { ipfs_rewrite } from "./ipfs";
import { proxy_fetch } from "./proxy";
import type { TokenQueryOrAddress, TokenQueryType } from "./types";

export const ERC721 = "721";

export const setApprovalForAll_method_signature =
  "function setApprovalForAll(address to, bool approved) external";
export const approve_erc721_method_signature =
  "function approve(address to, uint256 tokenId) external payable";

/**
 * ERC721 ABI.
 * @see https://eips.ethereum.org/EIPS/eip-721 to add other methods
 */
export const erc721 = [
  "function tokenURI(uint256) view returns (string)",
  "function ownerOf(uint256 _tokenId) external view returns (address)",
  "function balanceOf(address owner) view returns (uint256)",
  "function safeTransferFrom(address,address, uint256, bytes) external payable",
  "function safeTransferFrom(address, address, uint256) external payable",
  "function transferFrom(address, address, uint256) external payable",
  "function approve(address, uint256) external payable",
  "function setApprovalForAll(address, bool _approved) external",
  "function getApproved(uint256) external view returns (address)",
  "function isApprovedForAll(address, address) external view returns (bool)"
];

export const erc721Enumerable = [
  "function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint256)"
];

export const erc721Abi = parseAbi(erc721);
export const erc721EAbi = parseAbi([...erc721, ...erc721Enumerable]);

// @todo extends
export const abix_erc721 = (abi: ABI): ABIExtra => {
  return {
    id: `gen:${abi.addr.chain}:${abi.addr.addr}`,
    name: "Generated ABIx for NFT",
    // @todo _owner address book (could be overridden)
    values: {
      owner: { ty: "address", v: "$self" }
    },
    methods: {}
  };
};

/**
 * tokenURI retrieves the tokenURI data for a given collection.
 * @param coll
 * @param id
 * @returns
 */
export const tokenURI = <Addr extends TokenQueryOrAddress>(
  rpc: LocalRPCSubscriber,
  local: LocalSubscriber<CacheQuery>,
  coll: AnyCell<Addr>,
  id: AnyCell<number>,
  chain: AnyCell<ChainType>
) => {
  const proxy = rpc._proxy;
  const addr = proxy.mapNoPrevious(
    [chain, coll],
    // @ts-expect-error If starts by "nft:" then TokenQueryType
    async (_ch, _coll) => {
      if (_coll.toString()?.startsWith("nft:")) {
        // @todo check proxy
        const collection = local.unwrappedCell(coll as AnyCell<TokenQueryType>);
        return collection.map((c) =>
          c?.addr?.find((_addr) => _addr.chain === _ch)
        );
        // @todo flatten automatically
        // .get()
      }
      return { addr: _coll, chain: _ch, ty: ContractType };
    }
  ) as AnyCell<EVMAddress>;
  const tokenURI = rpc.call(
    addr,
    proxy.new(erc721Abi),
    proxy.new("tokenURI"),
    id.map((_id) => [id])
  ) as AnyCell<string>;
  return ipfs_rewrite(tokenURI);
};

export interface ERC721Metadata {
  name: string;
  description: string;
  image: string;
  properties?: ERC721Property[];
}

// /**
//  * Type definition for ERC721Metadata.
//  */
// export const ERC721MetadataDefinition: LabelledTypeDefinition = {
//   label: "ERC721 Metadata",
//   object: {
//     name: () => ({ label: "Name", base: "string" }),
//     description: () => ({ label: "Description", base: "string" }),
//     image: () => ({ label: "Image URL", base: "string" }),
//     properties: () => ({
//       label: "Properties",
//       object: {
//         trait_type: () => ({ label: "Trait type", base: "string" }),
//         value: () => ({ label: "Value", any: true }),
//         max_value: () => ({ label: "Max value", any: true, optional: true }),
//         display_type: () => ({
//           label: "Display type",
//           enum: ["string", "number"],
//           optional: true
//         })
//       },
//       optional: true
//     })
//   }
// };

export interface ERC721Property {
  trait_type: string;
  value: unknown;
  max_value?: unknown;
  display_type?: "string" | "number";
}

/**
 * tokenURI_JSON returns the tokenURI as a JSON.
 * @param coll
 * @param id
 * @param proxy
 * @returns
 */
export const tokenURI_JSON = (
  rpc: LocalRPCSubscriber,
  local: LocalSubscriber<CacheQuery>,
  chain: AnyCell<ChainType>,
  coll: AnyCell<Address>,
  id: AnyCell<number>,
  proxy?: string
): AnyCell<ERC721Metadata> => {
  const uri = tokenURI(rpc, local, coll, id, chain);
  // @todo convert Address?
  return proxy_fetch(local, uri, proxy).map((v) =>
    v.json()
  ) as AnyCell<ERC721Metadata>;
};

/**
 * returns the owner address of a NFT.
 * @param coll
 * @param id
 * @returns
 */
export const owner_of = <Addr extends TokenQueryOrAddress>(
  rpc: LocalRPCSubscriber,
  local: LocalSubscriber<CacheQuery>,
  chain: AnyCell<ChainType>,
  coll: AnyCell<Addr>,
  id: number // @todo cell of array?
): MapCell<Address, false> => {
  const proxy = local._proxy;
  const addr: MapCell<EVMAddress | undefined, false> = proxy.map(
    [chain, coll],
    (_ch, _coll) => {
      if (_coll?.toString().startsWith("nft:")) {
        // @todo check proxy
        const collection = local.unwrappedCell<TokenQueryType>(
          coll as AnyCell<TokenQueryType>
        );
        // .get();
        return collection.map((coll) =>
          coll?.addr?.find((_addr) => _addr.chain === _ch)
        );
      }
      return { addr: _coll, chain: _ch, ty: ContractType } as EVMAddress;
    }
  );

  const args = proxy.new([proxy.new(id)]);
  return rpc.call(
    addr,
    proxy.new(erc721Abi),
    proxy.new("ownerOf"),
    args
  ) as MapCell<Address, false>;
};

/**
 * getPropertyValue retrieves a property from an ERC721 metadata.
 * @param m metadata (as retrieved by `tokenURI_JSON`)
 * @param key
 * @returns
 */
export const getPropertyValue = (m: ERC721Metadata, key: string) =>
  m?.properties?.find((p) => p.trait_type === key)?.value;

// const cors_proxy = "https://cors-anywhere.herokuapp.com/";

/**
 * fetch_image fetches an image as ObjectURL.
 * @param uri
 * @param proxy
 * @returns
 */
export const fetch_image = async (uri: string, proxy?: string) => {
  const img = await fetch(proxy ? proxy + uri : uri);
  if (!img) return;
  const blob = await img.blob();
  if (!blob) return;
  return URL.createObjectURL(blob);
};

/**
 * token_image retrieves the image URL for a NFT.
 * @param coll
 * @param id
 * @todo investigate navigator.registerProtocolHandler
 */
export const token_image = (
  rpc: LocalRPCSubscriber,
  local: LocalSubscriber<CacheQuery>,
  chain: AnyCell<ChainType>,
  coll: AnyCell<Address>,
  id: AnyCell<number>,
  proxy?: string
) => {
  const json = tokenURI_JSON(rpc, local, chain, coll, id, proxy);
  const image = json.map((_json) => _json?.image);
  return ipfs_rewrite(image);
};

export const NFTNbOwned = 1;
export const NFTOwned = 2;

type CollectionOwners = { [owner: string]: string[] };

// @todo back
const nft_index_map = (
  local: LocalRPCSubscriber,
  addr: AnyCell<EVMAddress>,
  abi: AnyCell<Abi>,
  from = 0,
  to: number = from + 100
) => {
  const proxy = local._proxy;

  return proxy.map([addr, abi], async (_addr, _abi) => {
    const ids = [];
    const values = [];
    const ownerOf = proxy.new("ownerOf");

    for (let i = from; i < to; i++) {
      ids.push(i);

      const args = proxy.new([proxy.new(i)]);
      const res = local.call(addr, abi, ownerOf, args);
      values.push(res);
    }
    return { ids, values };
  });
};

const invert_nft_index = (
  res: AnyCell<{
    ids: number[];
    values: unknown[];
  }>
) => {
  const out: CollectionOwners = {};
  return res.map(async (res) => {
    for (let i = 0; i < res.ids.length; i++) {
      // @todo check types
      const addr = (await (res.values[i] as AnyCell<unknown>)?.get()) as string;
      if (!out[addr]) out[addr] = [];
      // @todo as string?
      out[addr].push(`${res.ids[i]}`);
    }
    return out;
  });
};

export const get_owned_nfts = (
  local: LocalRPCSubscriber,
  addr: AnyCell<EVMAddress>,
  abi: AnyCell<Abi>,
  owner: AnyCell<Address>
) => {
  const proxy = local._proxy;
  const balanceOf = proxy.new("balanceOf");
  const args = owner.map((_) => [owner]);
  const balance = local.call(addr, abi, balanceOf, args) as MapCell<
    bigint,
    boolean
  >;
  const tokenOfOwnerByIndexItem = abi.map(
    (abi) => getAbiItem({ abi: abi, name: "tokenOfOwnerByIndex" }) || null
  );

  const owners = nft_index_map(local, addr, abi);
  const tokenIds = proxy.map(
    [addr, abi, balance, tokenOfOwnerByIndexItem, owners, owner],
    async (
      _addr,
      _abi,
      _balance,
      _tokenOfOwnerByIndexItem,
      _owners,
      _owner
    ) => {
      const tokenOfOwnerByIndex = proxy.new("tokenOfOwnerByIndex");

      if (_tokenOfOwnerByIndexItem) {
        const tokenIds = [];
        for (let i = 0; i < _balance; i++) {
          const args = proxy.new([owner, proxy.new(i)]);
          const tokenId = local.call(addr, abi, tokenOfOwnerByIndex, args);
          tokenIds.push(tokenId);
        }
        return tokenIds;
      }
      const inverted = invert_nft_index(owners);
      // @ts-expect-error @todo fix inverted type
      return inverted.map((_inverted) => inverted[_owner]);
    }
  );
  return tokenIds;
};
