/**
 * detect_device performs mobile and platform device detection.
 */
export const detect_device = () => {
  const platform =
    navigator.platform ||
    // @ts-ignore userAgentData exists for some browsers
    (navigator?.userAgentData?.platform as string);
  const isMobile =
    window.matchMedia("(pointer:coarse)").matches ||
    window.matchMedia("(any-pointer:coarse)").matches;
  return platform !== undefined
    ? platform + (isMobile ? " m" : "")
    : isMobile
      ? navigator.userAgent.match(/Android/i)
        ? "Android"
        : navigator.userAgent.match(/iPhone/i)
          ? "iPhone"
          : navigator.userAgent.match(/iPad/i)
            ? "iPad"
            : "mobile"
      : "desktop";
};

/**
 * detectDevice performs mobile and platform device detection.
 */
export const detectDevice = async () => {
  let platform = "";
  let isMobile = false;

  // @ts-ignore userAgentData exists for some browsers
  if ("userAgentData" in navigator) {
    // @ts-ignore Use User-Agent Client Hints API
    const uaData = await navigator.userAgentData.getHighEntropyValues([
      "platform",
      "mobile"
    ]);
    platform = uaData.platform;
    isMobile = uaData.mobile;
  } else {
    // Fallback for browsers without userAgentData
    platform = navigator.platform || "unknown";
    isMobile =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      /Android|iPhone|iPad|Mobile|Tablet|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
  }

  return platform + (isMobile ? " mobile" : " desktop");
};
