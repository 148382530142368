import type { Abi } from "viem";

import type { AnyCell } from "@okcontract/cells";
import type { Rational } from "@okcontract/lambdascript";
import type { Address, ChainType, EVMAddress } from "@okcontract/multichain";

/**
 * Raw stored ThemeDefinition.
 * @copy from "@okcontract/uic"
 */
export const ThemeText = "tx";
export const ThemeButton = "bt";
export const ThemeBackground = "bg";
export const ThemeAccent = "act";
export const ThemeDark = "dark";
export type RawThemeDefinition = {
  [ThemeAccent]?: string;
  [ThemeBackground]: string;
  [ThemeButton]?: string;
  [ThemeText]?: string;
  dark: boolean;
};

import type {
  ABIExtraQueryType,
  AnonContractQueryType,
  ContractQueryType,
  OrgQueryType,
  TokenQueryType
} from "./coredata";

// @todo use everywhere
export const TokenCurrentChain = "token-chain";

/**
 * TokenMarketData is data about tokens as retrieved from the server cache.
 */
export type TokenMarketData = TokenItem & {
  image?: string;
  current_price?: Rational;
  price_change_24h?: Rational;
  market_cap?: Rational;
  total_volume?: Rational;
  total_supply?: Rational;
  status: string;
};

/**
 * TokenItem as sent by CoinGecko.
 */
export interface TokenItem {
  id: string;
  symbol: string;
  name: string;
}

/**
 * GasPriceData contains information about gas price.
 */
export interface GasPriceData {
  /** chain */
  ch: string;
  FastGasPrice: string;
  LastBlock: string;
  ProposeGasPrice: string;
  SafeGasPrice: string;
  gasUsedRatio: string;
  suggestBaseFee: string;
}

/**
 * BalanceData is a data containing a balance.
 * @todo Multiple tokens?
 */
export interface BalanceData {
  /** chain */
  ch: string;
  /** token ID */
  tok: string;
  /** address */
  addr: string;
  /** amount from *big.Int: FIXME */
  amount: number;
}

/**
 * ComplexProtocol is used for data retrieved from debank.
 */
export interface ComplexProtocol {
  id: string;
  chain: ChainType;
  name: string;
  site_url: string;
  logo_url: string;
  has_supported_portfolio: boolean;
  tvl: number;
  portfolio_item_list: PortfolioItem[];
}

/**
 * PortfolioItem holds data retrieved from debank.
 */
export interface PortfolioItem {
  update_at: number;
  name: string;
  detail_types: string[];
  detail: {
    supply_token_list: TokenInfo[];
    reward_token_list?: TokenInfo[];
    description?: string;
  };
  stats: TokenStats;
  proxy_detail?: unknown;
}

/**
 * TokenInfo holds data retrieved from debank.
 */
export interface TokenInfo {
  id: string;
  chain: ChainType;
  name: string;
  symbol: string;
  display_symbol?: string;
  optimized_symbol: string;
  decimals: number;
  logo_url: string;
  protocol_id: string;
  price: number;
  is_verified?: boolean | 0 | 1;
  is_core?: boolean | 0 | 1;
  is_wallet: boolean;
  time_at?: number;
  amount: number;
  raw_amount?: number;
}

export interface TokenStats {
  asset_usd_value: number;
  debt_usd_value: number;
  net_usd_value: number;
  daily_yield_usd_value: number;
  daily_cost_usd_value: number;
  daily_net_yield_usd_value: number;
}

/**
 * Pivot definition.
 */
export interface Pivot {
  /** pivot name (or entity) */
  name: string;
  /** pivot value */
  val: string;
  /** name of value containing the range (assuming the range is a number) */
  range: string;
  /** name of value containing the address of label of a given pivot value */
  label: string;
}

export type MessageItem =
  | `.${string}` // env value
  | `@${string}` // input value
  | string; // constant value

/**
 * ABIValues defines a map of method / ABIValue
 * @todo make sure we follow `datacache` types
 */
export interface ABIValues {
  [method: string]: ABIValue;
}

export const ALL_CHAINS = "ALL";

export const ABIValueTypes = [
  "string",
  "address",
  "number",
  "token",
  TokenCurrentChain,
  "contract",
  "nft",
  "swap",
  "stake",
  "image",
  "tuple",
  "bool",
  "bytes",
  "enum",
  ALL_CHAINS
] as const;

export const ABINumber = [
  "uint",
  "uint8",
  "uint16",
  "uint24",
  "uint32",
  "uint64",
  "uint128",
  "uint256",
  "int",
  "int8",
  "int16",
  "int24",
  "int32",
  "int64",
  "int128",
  "int256"
] as const;

export type ABINumbers = (typeof ABINumber)[number];
export type ABIValueType = (typeof ABIValueTypes)[number] | ABINumbers;

// // @todo unused
// export const ABIValueTypeToMonotype = (ty: ABIValueType) => {
//   switch (ty) {
//     case "bytes":
//     case "string":
//     case "image":
//     case "enum":
//       return typeString;
//     case "bool":
//       return typeBoolean;
//     case "number":
//     case "int":
//     case "int8":
//     case "int16":
//     case "int24":
//     case "int32":
//     case "int64":
//     case "int128":
//     case "int256":
//     case "uint":
//     case "uint8":
//     case "uint16":
//     case "uint24":
//     case "uint32":
//     case "uint64":
//     case "uint128":
//     case "uint256":
//       return typeNumber;
//     case "address":
//     case "token":
//     case TokenCurrentChain:
//     case "nft":
//     case "contract":
//     case "stake":
//     case "swap":
//       return typeAddress;
//     case "tuple":
//       throw new Error("tuple not supported");
//     default:
//       throw new Error(`${ty} not supported`);
//   }
// };

export const isABINumber = (type: unknown) =>
  [...ABINumber, "number"].find((n) => n === type);

/**
 * ABIValue defines how to evaluate parameters for method calls
 */
export interface ABIValue {
  /** type information */
  ty?: ABIValueType;
  /** name of value or function in env */
  v?: string;
  /** amount definition (λscript expression) */
  a?: string;
  /** default */
  def?: string;
  /** label */
  l?: string;
  /** enum */
  em?: string;
  /** full description */
  desc?: string;
  /** placeholder */
  pl?: string;
  /** min value (λscript expression) */
  min?: string;
  /** max value (λscript expression) */
  max?: string;
  /** external definition allowed */
  x?: boolean;
  /** optional */
  opt?: boolean;
  /** decimals */
  dec?: boolean;
  /** optional cell */
  cell?: AnyCell<unknown>;
}

export const ABIMethodTitle = "@t";
export const ABIMethodInfo = "@i";
export const ABIMethodButton = "@b";
export const ABIMethodImage = "@img";
export const ABIMethodAllowances = "@a";
export const ABIMethodRcpt = "@rcpt";
export const ABIMethodPre = "@pre";
export const ABIMethodAdm = "@adm";
export const ABIMethodIntent = "@int";

/**
 * Intent
 */
type Intent = {
  /** name */
  n: string;
  /** parameters */
  p: { [key: string]: string };
};

/**
 * ABIMethodFields defines ABIMethod specific fields
 */
export interface ABIMethodFields {
  /** method title */
  [ABIMethodTitle]?: string[];
  /** method information text */
  [ABIMethodInfo]?: string[];
  /** button name */
  [ABIMethodButton]?: string[];
  /** image URL (possibly ipfs://, possible expression) */
  [ABIMethodImage]?: string;
  /** list of approvals lambdascript expressions for keys and values) */
  [ABIMethodAllowances]?: { [key: string]: string };
  /** list of funds/tokens recipients (e.g. "to") */
  [ABIMethodRcpt]?: string[];
  /** pre-conditions (λscript expressions) */
  [ABIMethodPre]?: string[];
  /** is reserved for smart contract admin @todo extend to multiple roles? */
  [ABIMethodAdm]?: boolean;
  /** intent for the method */
  [ABIMethodIntent]?: Intent;
}

export const ABIMethodKeys = [
  ABIMethodTitle,
  ABIMethodInfo,
  ABIMethodButton,
  ABIMethodImage,
  ABIMethodAllowances,
  ABIMethodRcpt,
  ABIMethodPre,
  ABIMethodAdm,
  ABIMethodIntent
] as const;

export type ABIMethodKey = (typeof ABIMethodKeys)[number];
export const isABIMethodKey = (key: string): key is ABIMethodKey =>
  ABIMethodKeys.includes(key as ABIMethodKey);

/**
 * ABIMethod
 */
export type ABIMethod = ABIMethodFields & ABIValues;

export type ABIMethodType<Key extends string> =
  Key extends (typeof ABIMethodKeys)[number] ? ABIMethodFields[Key] : ABIValue;

/**
 * ABIExtra defines additional metadata for an ABI.
 */
export interface ABIExtra {
  /** identifier */
  id: string;
  /** name */
  name: string;
  /** description */
  desc?: string;
  /** pivot definition */
  pivot?: Pivot;
  /** error definitions (use ABIError) */
  err?: ABIError[];
  /** abi values */
  values?: ABIValues;
  /** method definitions */
  methods?: { [key: string]: ABIMethod };
  /** submitter address @todo remove optional once old datacache values are migrated */
  from?: string;
}

/**
 * ABIError are human-friendly error definitions.
 * @todo Use...
 */
export type ABIError = {
  /** pattern matched */
  p: string;
  /** should be ignored (default: false) */
  i?: boolean;
  /** human friendly message */
  h: string;
  /** type: warning (w), error (e), etc. */
  t?: string;
  /** actions if error (buttons, etc.) */
  ac?: string[];
};

/**
 * OKWidget defines a Widget for OKcontract.
 * @todo add Overrides
 */
export type OKWidget = {
  /** widget ID (for owner) */
  id: string;
  /** widget definition version */
  ver: number;
  /** display name */
  name: string;
  /** active */
  act: boolean;
  /** address of owner account */
  own: Address;
  /** eas query refID */
  qri?: string;
  /** authorized domains (all if empty) */
  dom?: string[];
  /** theme */
  // `${WidgetThemeProperty}:${WidgetThemeValue}`[];
  th?: RawThemeDefinition;
  /** common definitions */
  com?: OKWidgetStep<OKWidgetStepType>;
  /** steps @todo USE */
  st: OKWidgetStep<OKWidgetStepType>[];
  /** org */
  org?: string;
  /** text before connection */
  tbc?: string[];
  /** image before connection*/
  ibc?: string;
};

export const OKWidgetCallStep = "call";
export const OKWidgetSigStep = "sig";
export const OKWidgetAftStep = "aft";
export const OKWidgetWaitStep = "wait";
export const OKWidgetApproveStep = "app";
export const OKWidgetNetworkTXStep = "ntx";
/**
 * OKWidgetStepTypes defines types of action steps for widgets.
 */
export const OKWidgetStepTypes = [
  OKWidgetCallStep,
  OKWidgetSigStep,
  OKWidgetAftStep,
  OKWidgetWaitStep,
  OKWidgetApproveStep,
  OKWidgetNetworkTXStep
] as const;

export type OKWidgetStepType = (typeof OKWidgetStepTypes)[number];

/**
 * OKWidgetStep defines an widget action step.
 */
export interface OKWidgetStep<T extends OKWidgetStepType> {
  /** WidgetStepType */
  sty: T;
  /** optional org (lambdascript expression) */
  org?: string;
  /** Contract query ("con:xxx", "sign:"). RENAME: Action? */
  q?: ContractQueryType | TokenQueryType | AnonContractQueryType<ChainType>;
  /** Chain for token */
  chain?: ChainType;
  /** Method (optional, for "sign:") */
  m?: string;
  /** Sign expression (instead of contract interaction) */
  sig?: string;
  /** Datacache query for network-managed transaction */
  ntx?: string;
  /** Force chains */
  fch?: ChainType[];
  /** ABIMethod definition, overrides prior definitions */
  xm?: ABIMethod;
  /** λscript expression determining if we should skip the step */
  skip?: string;
  /** @deprecated Information text */
  // i?: string[];
  /** @deprecated Image URL (possibly ipfs://, possible expression) */
  // img?: string;
  /** @deprecated List of actions to run after widget interaction ("action:params") */
  // aft?: string[];
}

/**
 * NetworkTX defines a network-defined transactions. Such transactions
 * will be generated and signed by `datacache` nodes, enabling light clients.
 */
export interface NetworkTX {
  /**  query id (e.g. "ods" for OKX DEX Swap) */
  id: string;
  /**  query parameters (e.g. {chain:"arbitrum",tokenA:"tok:weth"}) */
  p: { [key: string]: string };
  /** destination contract: address as string */
  to: Address;
  /** 0x hex-encoded tx */
  tx: Address;
  /** optional sender: address as string */
  from?: Address;
  /** optional native chain value (unparsed bigint) */
  value?: string;
  /** approvals */
  apv?: NetworkApproval[];
  /** metadata */
  data?: { [key: string]: unknown };
}

export interface NetworkApproval {
  /** token address */
  t: Address;
  /** spender address */
  s: Address;
  /** optional amount (or "infinite" if undefined) as stringified number */
  a?: string;
}

/**
 * SmartContract holds data retrieved from DataCache
 */
export interface SmartContract {
  /**
   * identifier for a given org: org/id
   * @todo versions
   */
  id?:
    | `${string}/${string}`
    | `${string}/${string}_v${string}`
    | `${string}/${string}_v${string}_${ChainType}`;
  /** display name */
  name?: string;
  /** submitter address */
  from: string;
  /** fully qualified address */
  addr: EVMAddress[];
  /** is the contract active */
  act: boolean;
  /** longer description */
  desc?: string;
  /** source code repository (in Solidity) */
  src?: string;
  /** deprecated - ABI definition */
  // abi?: ABI;
  /** the token query type if it exists */
  tok?: TokenQueryType;
  /** URI of the official doc */
  doc?: string;
  /** deprecated - ABIExtra definitions */
  // x?: ABIExtra;
  /** pointers to ABIExtra references */
  xr?: ABIExtraQueryType[];
}

/**
 * PoolTokenType type of the token: Basic (by default), Stake, SwapPool, LendPool.
 */
export type PoolTokenType = "token" | "stake" | "swap" | "lend" | "nft";

export type TokenID = string;

export type ForeignSource =
  | "coingecko"
  | "debank"
  | "cmc"
  | "llama"
  | "opensea"
  | "coinlist";
export type ForeignID = `${ForeignSource}:${string}`;

/**
 * EVMToken holds data retrieved from DataCache
 */
export type EVMToken = TokenItem & {
  /** org id */
  org?: OrgQueryType;
  /** is the token active */
  act: boolean;
  /** submitter address */
  from?: string;
  /** type of the token: Basic, Stake, SwapPool, LendPool */
  ty?: PoolTokenType;
  /** underlying tokens for pools (1..n) */
  toks?: TokenID[];
  addr: EVMAddress[];
  /** foreign ids for the token */
  foreign?: ForeignID[];
  /** decimals */
  decimals?: number;
  url?: string;
  logo?: string;
  /** description for NFT */
  desc?: string;
  /** list of communication channels */
  chans?: ChannelValue[];
};

export type ERCType = "20" | "223" | "721" | "777" | "1155";

/**
 * ABI data definition.
 */
export interface ABI {
  /** contract address */
  addr: EVMAddress;
  /**
   * ABI as string
   * @todo rename to json (back...)
   */
  abi: string;
  /** optional error */
  err?: string;
  /** ABI parsed */
  parsed?: Abi;
  /** Human ABI */
  human?: string[];
  /** Unknown Signatures */
  unk?: string[];
  /** Submitter */
  from?: Address;
  /** ERC Types */
  erc?: ERCType[];
}

/**
 * ChannelType types used in organizations as "chans": ["Tw:id", "Gh:id"]
 *  Website   ChannelType = "Web" // URL
    Email     ChannelType = "Em"  // address
    Other     ChannelType = "O"   // URL
    Twitter   ChannelType = "Tw"  // id
    GitHub    ChannelType = "Gh"  // id
    Discord   ChannelType = "Dis" // URL
    Telegram  ChannelType = "Tg"  // id
    Reddit    ChannelType = "Rdt" // id
    Medium    ChannelType = "Med" // id
    Instagram ChannelType = "Ins" // id
    ENSAddress ChannelType = "ENS" // id
 */
export type ChannelType =
  | "Web" // Website
  | "Em" // Email
  | "O" // Other
  | "Tw" // Twitter
  | "Gh" // GitHub
  | "Dis" // Discord
  | "Tg" // Telegram
  | "Rdt" // Reddit
  | "Med" // Medium
  | "Ins" // Instagram
  | "Lin" // Linkedin
  | "ENS"; // ENS

export type ChannelValue = `${ChannelType}:${string}`;

/**
 * Organization holds data from DataCache
 */
export interface Organization {
  id: string;
  name?: string;
  from: Address;
  /** later editors for larger orgs */
  mem?: Address[];
  rugged?: boolean;
  logo?: string;
  /** description */
  desc?: string;

  /** app URL */
  app?: string;
  banner?: string;
  /** list of communication channels */
  chans: ChannelValue[];
  foreign: ForeignID[];
  /** investments in other orgs */
  invest?: string[];

  // @todo graph edges

  /** list of org tokens IDs (including pools, etc.) */
  toks: TokenQueryType[];
  /** list of contracts IDs - reverse computed */
  cons?: ContractQueryType[];
  /** investors in this org (both orgs and people) - reverse computed */
  investors?: (`org:${string}` | `pp:${string}`)[];
  /** people belonging to this org - reverse computed */
  people?: `pp:${string}`[];
}

/**
 * LlamaProtocol holds data from DefiLlama
 */
export interface LlamaProtocol {
  id: string;
  name: string;
  address: string;
  symbol: string;
  url: string;
  description: string;
  chain: string;
  logo: string;
  audits?: string; // numbers
  audit_note?: string;
  gecko_id?: string;
  cmcId?: string;
  category?: string;
  chains?: string[];
  module?: string;
  twitter?: string;
  audit_links?: string[];
  forkedFrom?: string[];
  listedAt?: number; // date
  oracles?: string[];
  language?: string;
  slug: string;
  tvl?: number;
  chainTvls?: { [key: string]: number }; // cspell:disable-line
  change_1h?: string;
  change_1d?: string;
  change_7d?: string;
  staking?: string;
  pool2?: string;
  fdv?: string;
  mcap?: string; // cspell:disable-line
}

/** Person holds person data */
export interface Person {
  name: string;
  /** account address (or could be username?) */
  acc: string;
  pfp?: string;
  bio?: string;
  chans?: string[];
  /** orgs that the person belongs to */
  orgs: string[];
  /** orgs that the person invested in */
  invest?: string[];
  /** since */
  sce?: number;
  /** badges */
  bdg?: string[];
}

/** data_chunk is used to create an organization and its tokens and contracts from ui */
export interface data_chunk {
  organization: Organization;
  tokens: EVMToken[];
  contracts: SmartContract[];
}

/** IPFSUpload is an uploaded resource to IPFS */
export interface IPFSUpload {
  wid: string; // widget ID
  addr: string; // signer
  cid: string; // CID (enforce v1?)
  seq?: number; // sequence
}

/**
 * OKURL is a whitelisted OK URL.
 */
export interface OKURL {
  id: string; // URL ID
  v: string; // URL value
}

/**
 * AccountName is the name of a user account.
 */
export interface AccountName {
  /** name */
  n: string;
  /** source of the name */
  src: "users" | "ab" | "none";
}
