import { isContract, isToken, type ABIMethod } from "@okcontract/coredata";

export type ExtraParameters = Record<string, unknown>;

/**
 * toLambdaExpr extracts additional parameters for a widget and convert to a lambdascript expresion.
 * @description This function is responsible for checking the security
 * and therefore is only accepting token and contract queries at the time.
 * @param input formatted like `tokenA:tok:weth,tokenB:tok:snx`
 * @param noCheck is used to disable params validation
 * @todo parameter only for specific step?
 */
export const toLambdaExpr = (input: string, noCheck = false): ExtraParameters =>
  Object.fromEntries(
    input
      .split(",")
      .map((item) => {
        const pos = item.indexOf(":");
        if (pos) {
          return [item.substring(0, pos), item.substring(pos + 1)] as [
            string,
            string
          ];
        }
      })
      // filter valid values only
      .filter((arr) => arr !== undefined && (is_valid_param(arr[1]) || noCheck))
      // convert to lambdascript expressions
      .map(([k, v]) => [k, `"${v}"`])
  );

const is_valid_param = (v: string) => isToken(v) || isContract(v);

/**
 * merge_xp merges external parameters with a method definition.
 * @description this function checks that the ABIMethod allows external parameters.
 * @param xm
 * @param xp
 * @returns updated method
 */
export const merge_xp = (xm: ABIMethod, xp: ExtraParameters): ABIMethod => {
  if (!xm) return;
  if (!xp) return xm;
  const copy = { ...xm };
  for (const key in xp) {
    if (!key.startsWith("@") && copy[key] && copy[key].x) {
      copy[key] = { ...copy[key], v: xp[key] as string };
    }
  }
  return copy;
};
