import type { Connector } from "@wagmi/connectors";
import type { AbiFunction, AbiParameter, AbiType } from "abitype";
import { getAbiItem, parseAbi, toFunctionSelector, type Abi } from "viem";

import {
  Cell,
  simplifier,
  type AnyCell,
  type Cellified,
  type MapCell,
  type SheetProxy
} from "@okcontract/cells";
import {
  ABIQuery,
  ChainQuery,
  TokenCurrentChain,
  type ABI,
  type ABIMethod,
  type ABIValue,
  type ABIValueType,
  type CacheQuery
} from "@okcontract/coredata";
import {
  mapTypeDefinitions,
  type LabelledTypeDefinition,
  type TypeDefinition,
  type TypeDefinitionFn,
  type TypeDefinitionNumber
} from "@okcontract/fred";
import {
  Rational,
  newTypeObject,
  parseExpression,
  typeBoolean,
  typeList,
  typeNumber,
  typeString,
  type ASTNode,
  type Environment,
  type MonoType
} from "@okcontract/lambdascript";
import {
  ContractType,
  nativeBalance,
  type Address,
  type Chain,
  type ChainType,
  type EVMAddress,
  type LocalRPCSubscriber
} from "@okcontract/multichain";
import type { LocalSubscriber } from "@scv/cache";
import { asyncReduce } from "@scv/utils";

import type { CoreExecution } from "./coreExecution.types";
import { approve_method, getBalance, getDecimals, getSymbol } from "./erc20";
import { fixedArrayRegexp, isArray, isFixedArray } from "./format";
import type { Instance } from "./instance";
import { EnvKeyChain, EnvKeyPivot } from "./keys";
import { AddressExtension, typeAddress } from "./lambdascript";
import { resolveExpr } from "./resolve";
import { isRealWalletID } from "./wallet";

const fixedSizeArray = /\[(\d+)\]/;
const maxOf = {
  int8: 127,
  int16: 32767,
  int24: 256 ** 3 / 2 - 1,
  int32: 256 ** 4 / 2 - 1,
  int64: 256 ** 8 / 2 - 1,
  int128: 256 ** 16 / 2 - 1,
  int256: BigInt("2") ** BigInt("255") - BigInt("1"),
  uint8: 255,
  uint16: 65535,
  uint24: 256 ** 3 - 1,
  uint32: 256 ** 4 - 1,
  uint64: 256 ** 8 - 1,
  uint128: 256 ** 16 - 1,
  uint256: BigInt("2") ** BigInt("256") - BigInt("1")
};

export const ARG_PREFIX = "__arg";
const ARG_REGEX = /^__arg(\d+)$/;
export const anonymousArg = (i: number) => `${ARG_PREFIX}${i}`;
export const isAnonymousArg = (arg: string): boolean => ARG_REGEX.test(arg);
export const extractArgIndex = (arg: string): number | null => {
  const match = arg.match(ARG_REGEX);
  return match ? Number.parseInt(match[1], 10) : null;
};
export const getArgName = (pt: AbiParameter, i: number) =>
  pt?.name || anonymousArg(i);

/**
 * inputsPosition map inputs to values
 * @param fn
 * @param obj
 * @returns
 */
export const inputsPosition = (fn: AbiFunction, obj: Environment) =>
  fn?.inputs?.map((pt, i) => {
    const name = getArgName(pt, i);
    const value = obj.value(name) as AnyCell<unknown>;
    return value || null;
  });

/**
 * isArrayDefined checks if an array contains no undefined values (recursively).
 * @param x
 * @returns
 */
const isArrayDefined = (x: unknown) =>
  !Array.isArray(x) ||
  x.reduce((acc, v) => acc && v !== undefined && isArrayDefined(v), true);

/**
 * isInputDefined checks if a given input is defined
 */
export const isInputDefined = (isOK: boolean, input: unknown): boolean =>
  isOK && input !== null && isArrayDefined(input);

/** make ABI creates a new ABI object */
export const makeABI = (
  chain: ChainType,
  addr: Address,
  abi: string[]
): ABI => {
  const stringified = JSON.stringify(abi);
  return {
    addr: { chain, addr, ty: "c" },
    abi: stringified,
    parsed: parseAbi(abi)
  };
};

/**
 * getABI returns an ABI
 * @param con
 * @returns
 */
export const getABI = (
  proxy: SheetProxy,
  local: LocalSubscriber<CacheQuery>,
  addr: AnyCell<EVMAddress>,
  parsedAbi?: Abi
): AnyCell<ABI> => {
  // @todo consequences?
  // if (!addr?.addr) return null;
  const query = addr.map((_addr) =>
    _addr?.chain ? ABIQuery(_addr.chain, _addr?.px || _addr.addr) : null
  );
  // @todo check right proxy...
  // @todo useless if parsedAbi?
  const abi = local.unwrappedCell(query, "abi");
  const parsed = abi.map((_abi) =>
    parsedAbi
      ? parsedAbi
      : _abi?.abi
        ? (JSON.parse(_abi.abi) as Abi)
        : _abi?.human
          ? parseAbi(_abi.human)
          : null
  );
  return proxy.map([abi, parsed], (_abi, _parsed) => ({
    ..._abi,
    parsed: _parsed
  }));
};

/**
 * ABIMethodToASTMap takes an ABIMethod, discards its ABIMethodFields
 * and parses each ABIValue expression into its ASTNode.
 *
 * @param method the ABIMethod to parsed expressions.
 * @returns a map of ASTNode
 */
export const ABIMethodToASTMap = async (
  method: ABIMethod
): Promise<{ [name: string]: ASTNode }> => {
  return method
    ? asyncReduce(
        Object.entries(method),
        async (acc, [name, value]) => {
          try {
            if (name.startsWith("@")) return acc;
            if (value && value?.v !== undefined) {
              return {
                ...acc,
                [name.toLowerCase()]: await parseExpression(value?.v, {
                  ext: [AddressExtension]
                })
              };
            }
            return acc;
          } catch (error) {
            console.log("ABIMethodToASTMap failed: ", { name, value, error });
            return acc;
          }
        },
        {}
      )
    : undefined;
};

/**
 * abiArgDefinition return a definition for a given ABI argument
 * @param proxy
 * @param env
 * @param fn
 * @param a
 * @param pt
 * @returns
 */
export const abiArgDefinition = (
  instance: Instance,
  env: AnyCell<Environment>,
  fn: AnyCell<AbiFunction>,
  a: AnyCell<ABIValue>,
  pt: AnyCell<AbiParameter>
): AnyCell<LabelledTypeDefinition> => {
  const proxy = instance._proxy;
  const abiValue = proxy.map(
    [a, pt],
    (_a, _pt) => ({
      ...(_a || {}),
      ty: _a?.ty || _pt?.type
    }),
    "abiArgDefinition.abiValue"
  ) as AnyCell<ABIValue>;
  return proxy.map(
    [pt, abiValue, env, fn],
    async (_pt, _a, _env, _fn) => {
      const _ty = _a?.ty;
      // console.log({ _ty, _pt, _a, _fn });
      if (_ty === undefined || typeof _ty !== "string") return null;
      if (_ty.endsWith("[]")) {
        return {
          label: "",
          array: () =>
            // @todo collect
            abiArgDefinition(
              instance,
              env,
              fn,
              a,
              pt.map((_pt) => ({ ..._pt, type: _pt.type.replace("[]", "") }))
            )
        };
      }
      const fixedArray = _ty.match(fixedSizeArray);
      if (fixedArray) {
        return {
          label: "",
          array: () =>
            abiArgDefinition(
              instance,
              env,
              fn,
              a,
              pt.map((_pt) => ({
                ..._pt,
                type: _pt.type.substring(0, _pt.type.indexOf("["))
              }))
            ),
          max: Number(fixedArray[1])
        };
      }
      // @todo in this call abiValue is **not** Cellified
      return ABIValueToTypeDefinition(instance, abiValue, env, {
        pt,
        fn,
        label: fn?.name
      });
    },
    "abiArgDefinition"
  );
};

// @todo rewrite with cells, should return MapCell<LabelledTypeDefinition>
// https://github.com/okcontract/roadmap/issues/518
export const ABIValueToTypeDefinition = (
  instance: Instance,
  // @todo should be only one type, probably cellified...
  value: AnyCell<ABIValue> | Cellified<ABIValue>,
  env: AnyCell<Environment>,
  opts?: {
    pt?: AnyCell<AbiParameter>;
    fn?: AnyCell<AbiFunction>;
    label?: string;
  }
): MapCell<LabelledTypeDefinition, false> => {
  const proxy = instance._proxy;

  const ty = value.map(
    (_value) => _value?.ty || null,
    "ABIValueToTypeDefinition.ty"
  ) as AnyCell<ABIValueType>;
  const pt = opts?.pt || (instance.null as AnyCell<AbiParameter>);

  const fn = opts?.fn || instance.null;
  // @todo type return a cell for the number cases
  return proxy.mapNoPrevious(
    [value, ty, pt, env, fn],
    // @ts-ignore can't unify AnyCell<LabelledTypeDefinition> and LabelledTypeDefinition
    async (_value, _ty, _pt, _env, _fn) => {
      if (!_ty) return null;
      switch (_ty) {
        case "bytes":
          return { label: _pt?.name || "", base: "string", isBinary: true };
        case "string":
          return {
            label: _pt?.name || "",
            base: "string",
            // FIXME: we should not rely on this hack, but on schema definitions
            // in ABIExtra or OKWidget instead
            isImg: _pt?.name?.startsWith("image") || undefined
          };
        case "bool":
          return { label: _pt?.name || "", base: "boolean" };
        case "number":
        case "int":
        case "int8":
        case "int16":
        case "int24":
        case "int32":
        case "int64":
        case "int128":
        case "int256":
        case "uint":
        case "uint8":
        case "uint16":
        case "uint24":
        case "uint32":
        case "uint64":
        case "uint128":
        case "uint256": {
          const address: AnyCell<Address | null> = _value?.a
            ? ((await _env?.evaluateString(
                _value.a instanceof Cell
                  ? await _value.a.get()
                  : `$address(${_value.a})`
              )) as AnyCell<Address>)
            : _env.value(EnvKeyPivot)
              ? ((await _env?.evaluateString(
                  "$address($env.pivot)"
                )) as AnyCell<Address>)
              : instance.null;

          const ch = env.map((_env) => _env.value(EnvKeyChain) || null);
          const evm = proxy.map(
            [address, ch],
            (_addr, ch) =>
              ({
                addr: _addr,
                chain: ch,
                ty: ContractType
              }) as EVMAddress
          );
          // @fixme below code is blocking notification of some cells once we drop drop the account
          // probably related to gc and cell destruction
          // const wallet = env.map((_env) => _env.values(EnvKeySelf))
          const balance = getBalance(
            instance._core,
            proxy,
            instance._rpc,
            evm,
            instance._core.WalletID
          );
          const unit = getSymbol(
            instance._local,
            instance._core,
            proxy,
            instance._rpc,
            evm,
            "ABIValueToTypeDefinition"
          );
          const decimals = getDecimals(
            instance._local,
            instance._core,
            proxy,
            instance._rpc,
            evm
          );
          const min: AnyCell<Rational> = proxy.map(
            [address],
            async (_addr) => {
              // console.log("ABIValueToTypeDefinition", { _addr, _value, _env });
              return _addr
                ? instance.zero
                : _value?.min
                  ? ((_env?.evaluateString(
                      // @todo remove this hack...
                      _value.min instanceof Cell
                        ? await _value.min.get()
                        : _value.min
                    ) || instance.null) as unknown as AnyCell<Rational>)
                  : _ty.startsWith("u")
                    ? instance.zero
                    : instance.null;
            },
            "min"
          );
          // @todo we should have both max and rangeMax
          const max = proxy.map(
            [address, balance],
            async (address, balance) => {
              return address
                ? balance
                : _value?.max
                  ? _env?.evaluateString(
                      _value.max instanceof Cell
                        ? await _value.max.get()
                        : _value.max
                    )
                  : maxOf?.[_ty] &&
                      typeof maxOf[_ty] === "number" &&
                      maxOf[_ty] < 256 ** 3 - 1
                    ? new Rational(maxOf[_ty])
                    : null;
            },
            "max"
          );
          const optional = address && _value?.opt === true;
          const uint256 = proxy.map(
            [min, max, unit, decimals],
            (min, max, unit, decimals) => {
              const td = {
                label: _value?.l || _pt?.name || "",
                hidden: _value?.a !== undefined && address === undefined,
                base: "number",
                optional,
                min: min === null ? undefined : min,
                max: max === null ? undefined : max,
                // ...(min !== undefined && min !== "--" ? { min: min } : {}),
                // ...(max !== undefined && max !== "--" ? { max: max } : {}),
                ...(unit !== undefined ? { unit: unit } : {}),
                isBig: true,
                infinite: _fn?.name?.startsWith(approve_method) || false, // @todo
                decimals
              } as TypeDefinitionNumber;
              // console.log("ABIValueToTypeDefinition", { min, td, _value });
              return td;
            }
          );
          return uint256;
        }
        case "address":
          return {
            label: _pt?.name || "",
            base: "string",
            isAddress: true
          };
        case "token":
        case TokenCurrentChain:
        case "nft":
        case "contract":
        case "stake":
        case "swap":
          // FIXME: add address book type definition
          // case "abook": // address book // cspell:disable-line
          return {
            label: _pt?.name || "",
            base: "string",
            isAddress: _pt?.type === "address",
            search: _ty
          };
        case "image":
          return {
            label: _pt?.name || "",
            base: "string",
            isImg: true
          };
        case "tuple": {
          if (!("components" in _pt)) break;
          const object = {};
          for (let i = 0; i < _pt?.components?.length; i++) {
            const component = _pt.components?.[i];
            // @todo optional label
            const def = abiArgDefinition(
              instance,
              env,
              fn,
              instance.null, // @todo no ABI Value?
              proxy.new(component, "component")
            );
            object[component.name] = () => def;
          }
          return {
            label: "",
            object: mapTypeDefinitions(proxy, object, `tuple:${_pt.name}`),
            border: _pt.name
          };
        }
        case "enum": {
          const res = await resolveExpr(
            _env,
            _value?.em || "[]",
            proxy.new([])
          );
          return res.map(
            (en) =>
              ({
                label: _pt?.name || "",
                enum: en
              }) as LabelledTypeDefinition,
            "enum"
          );
        }
        default:
          return { label: _pt?.name || "", base: "string" };
      }
    },
    `ABIValueToTypeDefinition:${opts?.label}`
  );
};

/**
 * typeFromABIParam creates a λs type from an AbiParameter.
 * @param AbiParameter
 * @returns
 * @see The generated structure should match the schema definition that
 * will create inputs in `ABIValueToTypeDefinition`.
 */
export const typeFromABIParam = (
  param: AbiParameter,
  av: ABIValue
): MonoType | null => {
  const aux = (ty: AbiType, param: AbiParameter) => {
    if (isArray(ty))
      return typeList(aux(ty.substring(0, ty.length - 2) as AbiType, param));
    if (isFixedArray(ty))
      // @todo keep the count in later versions of Lambdascript
      return typeList(aux(ty.replace(fixedArrayRegexp, "") as AbiType, param));
    if (ty.startsWith("uint")) return typeNumber;
    if (ty.startsWith("int")) return typeNumber;
    if (ty === "bool") return typeBoolean;
    if (ty.startsWith("bytes")) return typeString;
    if (ty === "string") return typeString;
    if (ty === "address") return typeAddress;
    if (ty === "tuple") {
      if (!("components" in param)) throw "components not found";
      return newTypeObject(
        Object.fromEntries(
          param.components.map((ct) => [ct.name, aux(ct.type as AbiType, ct)])
        )
      );
    }
    // @todo mapping type
    throw new Error(`Unsupported AbiParameter type: ${ty}`);
  };
  if (param === null) {
    if (av?.ty) {
      if (
        av.ty === "number" ||
        av.ty.startsWith("int") ||
        av.ty.startsWith("uint")
      )
        return typeNumber;
      if (av.ty === "bool") return typeBoolean;
      if (av.ty === "string" || av.ty === "enum") return typeString;
      if (
        av.ty === "contract" ||
        av.ty === "nft" ||
        av.ty === "token" ||
        av.ty === "token-chain"
      )
        return typeAddress;
    }
    throw new Error(`Unsupported AbiValue type: ${av?.ty}`);
  }
  return aux(param.type as AbiType, param);
};

export const findMethod = (m: ABIMethod, key: string): ABIValue | null => {
  const keyLow = key.toLowerCase();
  const id = Object.keys(m).find((k) => k.toLowerCase() === keyLow);
  // @todo exclude other keys?
  return (m?.[id] as unknown as ABIValue) || null;
};

/**
 * $valueDefinition returns a TypeDefinitionFn for the special $value.
 * @returns
 */
export const $valueDefinition = (
  local: LocalSubscriber<CacheQuery>,
  core: CoreExecution<Connector>,
  rpc: LocalRPCSubscriber,
  abi: AnyCell<ABI>
): TypeDefinitionFn => {
  const proxy = rpc._proxy;
  const chainID = abi.map((_abi) => _abi.addr.chain);
  const owner = core.WalletID.map(
    (_walletID) => (isRealWalletID(_walletID) && _walletID) || null,
    "$valueDefinition.owner"
  );
  // @todo balance could be part of Core?
  const balance = nativeBalance(proxy, rpc, chainID, owner);
  const chain = proxy.map(
    [chainID, core.Chains],
    (id, chains) => chains[ChainQuery(id)] || null
  );
  const tokenQuery = chain.map((_chain) => _chain.currency);
  const token = local.unwrappedCell(tokenQuery);
  const def = proxy.map(
    [token, balance],
    (_token, _balance) => {
      return {
        label: "Amount",
        base: "number",
        min: new Rational(0),
        max: new Rational(_balance?.toString() || 0),
        isBig: true,
        decimals: _token?.decimals || new Rational(18),
        unit: _token?.symbol
      } as LabelledTypeDefinition;
    },
    "$valueDefinition.def"
  );
  // console.log({ def });
  return () => def;
};

export const mergeExtraABIValue = (
  proxy: SheetProxy,
  from: AnyCell<TypeDefinition>,
  m: AnyCell<ABIMethod>, // @todo Cellified<ABIMethod>
  key: string
): MapCell<LabelledTypeDefinition, boolean> =>
  proxy.map(
    [from, m],
    (_from, _m) => {
      const av = findMethod(_m, key);
      return {
        ..._from,
        ...(av
          ? {
              label: av?.l || key,
              desc: av?.desc || key,
              pl: av?.pl || key
            }
          : undefined)
      };
    },
    `mergeExtraABIValue:${key}`
  );

/**
 * getAbiFunction searches in an ABI a given method or return the first
 * with at least one input
 * @param abi
 * @param name optional method name
 * @returns AbiFunction
 */
export const getAbiFunction = (abi: Abi, name?: string) =>
  (abi
    ? name
      ? getAbiItem({
          abi,
          name: name.includes("(") ? toFunctionSelector(name) || null : name
        }) || null
      : abi
        ? abi.find(
            (_meth) =>
              _meth.type === "function" &&
              (_meth?.inputs?.length > 0 || _meth.stateMutability === "payable")
          ) || null
        : null
    : null) as AbiFunction;
