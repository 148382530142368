<script lang="ts">
  import Config from "../../config.json";

  import type { LogEntry } from "@okcontract/multichain";
  import { newInstance } from "@okcontract/svelte-sdk";
  import { ThemeText, getTheme } from "@okcontract/uic";

  import Titles from "./Titles.svelte";

  // @todo should a cell
  export let log: LogEntry;

  const instance = newInstance("TXLog");

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const decode = instance.analyzeLog(
    instance._proxy.new(log),
    instance._core.CurrentChain
  );
  const title = instance.logTitle(instance._core.WalletID, decode);
</script>

{#if $title instanceof Error}
  {#if Config.dev}
    Error at title {$title}
  {/if}
{:else if $decode instanceof Error}
  {#if Config.dev}
    Error at decode {$decode}
  {/if}
{:else if $title && $decode}
  <div title={JSON.stringify($title)}>
    <div
      class="my-2 flex flex-wrap items-center px-1 {$decode &&
      'nftcd' in $decode
        ? 'justify-center space-x-2'
        : $decode && 'tcd' in $decode
          ? 'space-x-2'
          : 'space-x-3'} {theme.dark(
        $compiledTheme,
        'text-white dark:text-white',
        'text-gray-900 dark:text-gray-900',
        'dark:text-gray-300'
      )}"
      style={theme.apply($compiledTheme, [ThemeText])}
    >
      <Titles {instance} item={title} size="sm" />
    </div>
  </div>
  <!-- {:else} -->
  <!-- TEMP: DEACTIVATED FOR UI -->
  <!-- {#if DEV}
      {JSON.stringify(log)}
    {/if} -->
  <!-- <DataCacheSnippet dq={log.address} displayType={false} /> -->
{/if}
