import type { Connector } from "@wagmi/connectors";
import type { Abi, TransactionRequest } from "viem";

import {
  SheetProxy,
  cellify,
  type AnyCell,
  type Cellified,
  type MapCell,
  type ValueCell
} from "@okcontract/cells";
import {
  WidgetQuery,
  type AnonContractQueryType,
  type CacheQuery,
  type DataCacheType,
  type OKWidget,
  type OKWidgetStepType
} from "@okcontract/coredata";
import type { EditorMode, MapTypeDefinitions } from "@okcontract/fred";
import {
  Environment,
  Rational,
  defaultLibrary,
  newTypeScheme,
  newTypeVar,
  type StandardLibrary
} from "@okcontract/lambdascript";
import type { EnvironmentOptions } from "@okcontract/lambdascript/src/env"; // @todo export
import type { Value } from "@okcontract/lambdascript/src/eval";
import type {
  Address,
  ChainType,
  EVMAddress,
  EVMType,
  Network
} from "@okcontract/multichain";
import {
  estimateGas,
  nativeBalance,
  type LocalRPCSubscriber,
  type LogEntry
} from "@okcontract/multichain";
import type { LocalSubscriber } from "@scv/cache";
import type { OKData } from "@scv/cache/queryCache"; // @todo move

import { getABI } from "./abi";
import { retrieveAddress, retrieveAllAddresses } from "./address";
import { analyzeLog, analyzeLogs, type AnalyzedLog } from "./analyze";
import type { CoreExecution } from "./coreExecution.types";
import {
  call_method,
  formatAmount,
  formatBalance,
  getAllowanceForWallet,
  getBalance,
  getDecimals,
  getName,
  getSymbol,
  raw_balance
} from "./erc20";
import { owner_of, tokenURI_JSON, token_image } from "./erc721";
import { EnvKeyInstance } from "./keys";
import { AddressExtension, web3Library } from "./lambdascript";
import { logSearch, logTitle } from "./log";
import { findName } from "./name";
import type { OKContractManager } from "./okcontract";
import { proxy_fetch, proxy_head } from "./proxy";
import { coredataTypeScheme } from "./schema";
import { Stepper } from "./stepper";
import { generateTX, type Step } from "./steps";
import type {
  AnyAddress,
  ContractQueryType,
  TokenQueryOrAddress,
  TokenQueryType
} from "./types";
import { newWidget, newWidgetFrom } from "./widget";

export type InstanceOptions = {
  local?: boolean;
  rpc?: boolean;
  name?: string;
  stdlib?: boolean;
  wantedChain?: ValueCell<ChainType>;
};

export class Instance {
  _core: CoreExecution<Connector>;
  _local: LocalSubscriber<CacheQuery>;
  _rpc: LocalRPCSubscriber;
  _stdlib: StandardLibrary;
  _proxy: SheetProxy;
  readonly null: MapCell<null, true>;
  readonly zero: MapCell<Rational, true>;
  readonly wantedChain: ValueCell<ChainType>;
  // _proxy_local: SheetProxy;
  // _proxy_rpc: SheetProxy;

  constructor(
    core: CoreExecution<Connector>,
    options: InstanceOptions = { local: true, rpc: true }
  ) {
    this._core = core;
    this._proxy = new SheetProxy(core.Sheet, options?.name || "instance");
    this.null = this._proxy.new(null, "null") as unknown as MapCell<null, true>;
    this.zero = this._proxy.new(new Rational(0), "zero") as unknown as MapCell<
      Rational,
      true
    >;
    this.wantedChain =
      options?.wantedChain ||
      this._proxy.new(core.CurrentChain.get(), `i:${options?.name}.wc`);
    // logger(this.wantedChain);
    if (options.local) {
      // this._proxy_local = new SheetProxy(core.Sheet, `cache:${options.name}`);
      this._local = core.Local(undefined, this._proxy, options?.name);
      // @todo maybe we should not reuse the proxy?
      // this._proxy = this._local._proxy;
    }
    if (options.rpc) {
      // this._proxy_rpc = new SheetProxy(core.Sheet, `rpc:${options.name}`);
      this._rpc = core.LocalRPC(undefined, this._proxy, options?.name);
    }
    // if (onDestroy) onDestroy(this.destroy);
  }

  destroy() {
    if (this?._local) this._local.destroy();
    // if (this._proxy_local) this._proxy_local.destroy();
    if (this?._rpc) this._rpc.destroy();
    // if (this._proxy_rpc) this._proxy_rpc.destroy();
    this._proxy.destroy();
  }

  _newWeb3Library = (): StandardLibrary => ({
    ...defaultLibrary(this._proxy),
    ...web3Library(this._proxy)
  });

  /**
   * envWithWeb3Library adds the complete web3 standard library to an Environment.
   * @param instance
   * @param env
   * @returns
   */
  envWithWeb3Library = (env: Environment) => {
    // @todo we could remove this trick with the ability to access "self" in
    // Standard Library definitions
    // If there is no standard library, we create it.
    // @todo Is the option to create at start useless?
    if (!this._stdlib) this._stdlib = this._newWeb3Library();
    env._updateLib(this._stdlib);
    if (env?.options?.noInstance) return env;
    env.addValueType(
      EnvKeyInstance,
      // We cheat, this is an Instance class, not a Value.
      this as unknown as Value<unknown>,
      newTypeScheme(newTypeVar("i"), ["i"])
    );
    // chainable
    return env;
  };

  newEnvironment = (options?: EnvironmentOptions) => {
    return this.envWithWeb3Library(
      new Environment(this._proxy, {
        parseOptions: {
          // @todo check that we need both extension
          ext: [AddressExtension, { ...AddressExtension, elt: "NumberValue" }]
        },
        extensions: [AddressExtension],
        ...options
      })
    );
  };

  _dataCache<Q extends CacheQuery, V = OKData<Q> | null>(
    q: AnyCell<Q>,
    opts: {
      name?: string;
      returnErrors?: boolean;
    }
  ): AnyCell<V | null> {
    return this._local.unwrappedCell(q, opts?.name, opts?.returnErrors);
  }

  _formatAmount(
    v: AnyCell<number | string | bigint | Rational>,
    tok: AnyCell<TokenQueryOrAddress>,
    chain: AnyCell<ChainType>,
    showSymbol: AnyCell<boolean> = this._proxy.new(true)
  ) {
    return formatAmount(
      this._core,
      this._proxy,
      this._rpc,
      this._local,
      v,
      tok,
      chain,
      showSymbol
    );
  }

  _formatBalance(
    walletID: AnyCell<Address>,
    ch: AnyCell<ChainType>,
    tok: AnyCell<TokenQueryOrAddress>
  ) {
    return formatBalance(
      this._core,
      this._proxy,
      this._rpc,
      this._local,
      walletID,
      ch,
      tok
    );
  }

  _getABI(addr: AnyCell<EVMAddress>, abi?: Abi) {
    return getABI(this._proxy, this._local, addr, abi);
  }

  _rawBalance(
    wallet: AnyCell<Address>,
    ch: AnyCell<ChainType>,
    tok: AnyCell<TokenQueryOrAddress>
  ) {
    return raw_balance(
      this._core,
      this._proxy,
      this._rpc,
      this._local,
      wallet,
      ch,
      tok
    );
  }

  _getBalance<N extends Network>(
    addr: AnyCell<EVMAddress<N>>,
    owner: AnyCell<Address<N> | null>
  ) {
    return getBalance(this._core, this._proxy, this._rpc, addr, owner);
  }

  // @todo StarkNet
  nativeBalance(
    chain: AnyCell<ChainType>,
    owner: AnyCell<Address<EVMType> | null>
  ) {
    return nativeBalance(this._proxy, this._rpc, chain, owner);
  }

  _getDecimals<N extends Network>(addr: AnyCell<EVMAddress<N>>) {
    return getDecimals(this._local, this._core, this._proxy, this._rpc, addr);
  }

  _getSymbol<N extends Network>(addr: AnyCell<EVMAddress<N>>) {
    return getSymbol(this._local, this._core, this._proxy, this._rpc, addr);
  }

  _getName<N extends Network>(addr: AnyCell<EVMAddress<N>>) {
    return getName(this._local, this._core, this._proxy, this._rpc, addr);
  }

  _getAllowanceForWallet(
    wallet: AnyCell<Address>,
    addr: AnyCell<EVMAddress>,
    spender: AnyCell<Address>
  ) {
    return getAllowanceForWallet(this._core, this._rpc, wallet, addr, spender);
  }

  logTitle(wallet: AnyCell<Address>, aLog: AnyCell<AnalyzedLog>) {
    return logTitle(this._proxy, wallet, aLog);
  }

  logSearch<
    Prop extends string | null,
    Emitter extends AnyAddress | null,
    Filter extends {
      [k: string]: string | Address<Network> | number | bigint;
    } | null
  >(
    chain: AnyCell<ChainType>,
    logs: AnyCell<AnalyzedLog[][]>,
    step: AnyCell<number>,
    event: AnyCell<string>,
    prop: AnyCell<Prop>,
    emitter: AnyCell<Emitter>,
    filter: AnyCell<Filter>
  ) {
    return logSearch(
      this._proxy,
      this._local,
      chain,
      logs,
      step,
      event,
      prop,
      emitter,
      filter
    );
  }

  analyzeLog(log: AnyCell<LogEntry>, ch: AnyCell<ChainType>) {
    return analyzeLog(this, log, ch);
  }

  analyzeLogs(
    chain: AnyCell<ChainType>,
    logsCell: AnyCell<AnyCell<LogEntry>[]>
  ) {
    return analyzeLogs(this, chain, logsCell);
  }

  _retrieveAddress<Source extends AnyAddress>(
    ch: AnyCell<ChainType>,
    addr: AnyCell<Source>
  ) {
    return retrieveAddress(this._proxy, this._local, ch, addr);
  }

  _retrieveAllAddresses(
    ch: AnyCell<ChainType>,
    l: AnyCell<AnyCell<AnyAddress>[]>
  ) {
    return retrieveAllAddresses(this._proxy, this._local, ch, l);
  }

  _generateTX(
    addr: AnyCell<EVMAddress>,
    data: AnyCell<`0x${string}`>,
    value: AnyCell<Rational>,
    estimateGas = true
  ) {
    return generateTX(
      this._rpc,
      this._rpc._proxy,
      this._core,
      addr,
      data,
      value,
      estimateGas
    );
  }

  _estimateGas(ch: AnyCell<ChainType>, tx: AnyCell<TransactionRequest>) {
    return estimateGas(this._proxy, this._rpc, ch, tx);
  }

  _proxyFetch(uri: AnyCell<string>) {
    return proxy_fetch(this._local, uri);
  }

  _proxyHead(uri: AnyCell<string>) {
    return proxy_head(this._local, uri);
  }

  _findName(ch: AnyCell<ChainType>, addr: AnyCell<Address>) {
    return findName(
      this._rpc,
      this._proxy,
      this._core.DefaultContracts,
      ch,
      addr
    );
  }
  _coredataTypeScheme(
    isAdmin?: boolean,
    mode?: EditorMode,
    type?: DataCacheType,
    methods?: string[]
  ) {
    return coredataTypeScheme(this, isAdmin, mode, type, methods);
  }

  _newWidget(
    types: MapTypeDefinitions,
    contractQuery?:
      | TokenQueryType
      | ContractQueryType
      | AnonContractQueryType<ChainType>,
    method?: string
  ) {
    // @todo merge libwidget in SDK
    // @ts-expect-error @todo conflict between sdk/types.ts and libwidget
    return newWidget(this._core, this._proxy, types, contractQuery, method);
  }

  _newWidgetFrom(types: MapTypeDefinitions, from: OKWidget) {
    return newWidgetFrom(this._core, this._proxy, types, from);
  }

  _callMethod<Args extends AnyCell<unknown>[]>(
    chain: AnyCell<ChainType>,
    q: AnyCell<AnyAddress>,
    meth: AnyCell<string>,
    args: AnyCell<Args>
  ) {
    return call_method(this._rpc, this._local, chain, q, meth, args);
  }

  _tokenImage(
    chain: AnyCell<ChainType>,
    coll: AnyCell<Address>,
    id: AnyCell<number>,
    proxy?: string
  ) {
    return token_image(this._rpc, this._local, chain, coll, id, proxy);
  }

  _tokenURI(
    chain: AnyCell<ChainType>,
    coll: AnyCell<Address>,
    id: AnyCell<number>,
    proxy?: string
  ) {
    return tokenURI_JSON(this._rpc, this._local, chain, coll, id, proxy);
  }

  _ownerOf(
    chain: AnyCell<ChainType>,
    coll: AnyCell<TokenQueryType | Address>,
    id: number // @todo cell of array?
  ) {
    return owner_of(this._rpc, this._local, chain, coll, id);
  }

  stepper(id: string) {
    // console.log("NEW STEPPER", id);
    // load widget
    const q = this._proxy.new(WidgetQuery(id), "q");
    const widgetCell = this._local.unwrappedCell(q);
    const widgetSteps = widgetCell.map((_widgetCell) =>
      cellify(this._proxy, _widgetCell.st)
    ) as unknown as Cellified<Step<OKWidgetStepType>[]>;
    const stepper = new Stepper(this, widgetSteps);
    return stepper;
  }

  /**
   * Create new Stepper.
   * @param okc manager
   * @param wid widget ID
   * @param chain wanted chain
   * @returns
   * @todo merge with previous
   */
  _NewStepper(okc: OKContractManager, wid: string) {
    const widCell = this._proxy.new(WidgetQuery(wid), "widCell");
    const widget = this._dataCache(widCell, {
      name: "widget",
      returnErrors: true
    });
    const steps = this._proxy.map([widget], (w) =>
      cellify(this._proxy, w.st)
    ) as unknown as Cellified<Step<OKWidgetStepType>[]>;
    return new Stepper(this, steps);
  }
}
