<script lang="ts">
  import { AlertBanner } from "@okcontract/uic";
  import { app_state, errors } from "@scv/app";
  import { navigate } from "@scv/routing";
  import { plural } from "@scv/utils";

  const message = (text) => {
    if (text.match(/unauthorized/i)) {
      return {
        text: "Connection not authorized, please login.",
        link: "/login"
      };
    }
    return { text };
  };

  $: $app_state?.errors?.length && setTimeout(errors.reset, 6000);
</script>

{#if $app_state.errors.length > 0}
  <AlertBanner action={errors.reset} style="error" icon="error">
    <div class="flex flex-row flex-wrap">
      <span class="font-semibold mr-1"
        >{plural("error", $app_state.errors)}:</span
      >
      <ul class="flex flex-row flex-wrap">
        {#each $app_state.errors as err}
          {#if message(err).link}
            <li>
              <button on:click={() => navigate({ to: message(err).link })}
                >{message(err).text}
              </button>
            </li>
          {:else}
            <li>{message(err).text}</li>
          {/if}
        {/each}
      </ul>
    </div></AlertBanner
  >
{/if}
