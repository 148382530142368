import { getContext, onDestroy } from "svelte";

import type { Connector } from "@wagmi/connectors";

import {
  type CoreExecution,
  CoreExecutionContextKey,
  Instance,
  type InstanceOptions
} from "@okcontract/sdk";

export const useCore = (): CoreExecution<Connector> =>
  getContext(CoreExecutionContextKey);

/**
 * instance creates a local instance for a Svelte component of the OKcontract SDK.
 * @returns instance
 * @description instance is automatically destroyed on component shutdown.
 */
export const newInstance = (
  name?: string,
  options: InstanceOptions = {}
): Instance => {
  // console.warn("create Instance", name);
  const core = useCore();
  const instance = new Instance(core, {
    local: true,
    rpc: true,
    name,
    ...options
  });
  onDestroy(() => {
    // console.warn("destroy Instance", name);
    // window.setTimeout(() => instance.destroy(), 10);
    instance.destroy();
  });
  return instance;
};
