import type { AnyCell, SheetProxy, ValueCell } from "@okcontract/cells";
import type { ContractQueryType } from "@okcontract/coredata";
import {
  EDIT,
  NEW,
  defaultPatterns,
  objectDefinition,
  type EditorMode,
  type EditorNode,
  type GroupDefinition,
  type LabelledTypeDefinition
} from "@okcontract/fred";
import type { Environment } from "@okcontract/lambdascript";

import { orgID } from "./data-org";
import type { Instance } from "./instance";

export const contract_groups = (
  proxy: SheetProxy,
  admin: boolean
): AnyCell<GroupDefinition>[] => [
  proxy.new({ id: "id", l: "Contract Information" }),
  proxy.new({ id: "addr", l: "Contract Addresses" }),
  proxy.new({ id: "add", l: "Additional Information" })
  //proxy.new({ id: "adv", l: "Advanced configuration", col: true, hid: !admin })
];

export const contractIDSchema =
  (instance: Instance, mode?: EditorMode, is_admin = false) =>
  (node?: EditorNode, env?: Environment): AnyCell<LabelledTypeDefinition> =>
    objectDefinition(
      instance._proxy,
      {
        org: orgID(instance, is_admin, mode),
        name: () =>
          instance._proxy.map([instance._core.Chains], (chains) => ({
            label: "Unique ID",
            base: "string",
            pl: "router_v2_polygon",
            hint: "Unique contract identifier",
            help: "Should be an id with optional version and chain, ex: router_v2_polygon",
            // TODO: complete regex
            pattern: defaultPatterns.contractid(
              Object.entries(chains).flatMap((_chain) => [
                _chain[0].substring(1)
              ])
            ),
            // def: mode === EDIT && !is_admin && UNAVAILABLE_DATA,
            def: mode === EDIT && !is_admin
          }))
      },
      "",
      {
        def: "/",
        inline: true,
        hint: "Represents the ID of the contract {orgName}/{uniqueID} ex: gmx/router",
        gr: "id",
        locked: mode === EDIT && !is_admin,
        lens: (v: ValueCell<string>) => {
          const org: ValueCell<string> = instance._proxy.new(
            undefined,
            "lens:org"
          );
          const name: ValueCell<string> = instance._proxy.new(
            undefined,
            "lens:name"
          );
          v.subscribe((_v) => {
            console.log({ _v });
            const l = _v.split("/");
            if (l.length !== 2) throw new Error("input must be: org/name");
            org.set(l[0]);
            name.set(l[1]);
          });
          const obj = instance._proxy.map(
            [org, name],
            (_org, _name) => ({ org, name }),
            "lens:obj"
          );
          // works only with isEqual, otherwise this would create a loop
          obj.subscribe(async (_obj) => {
            if (_obj instanceof Error) throw new Error(`lens failed: ${_obj}`);
            const nv = `${_obj.org.value}/${_obj.name.value}`;
            v.set(nv);
          });
          return obj;
        },
        validator: async (v: { org: string; name: string }) => {
          if (!v?.org || !v?.name) return;
          const r = await instance._core.CacheOnce(
            `con:${v.org}/${v.name}` as ContractQueryType
          );
          if (r?.id && mode === NEW) {
            return `Unique ID ${v.name} already exists`;
          }
        }
      }
    );

export const contractSchema =
  (instance: Instance, mode?: EditorMode, is_admin = false) =>
  (node?: EditorNode, env?: Environment) =>
    objectDefinition(
      instance._proxy,
      {
        id: contractIDSchema(instance, mode, is_admin),
        act: {
          label: "Contract activation",
          base: "boolean",
          hidden: true,
          gr: "id"
        },
        name: {
          label: "Name",
          base: "string",
          pl: "GMX Router",
          hint: "Name of your contract ex: GMX Router",
          gr: "id",
          min: 3
        },
        from: {
          label: "Submitter address",
          base: "string",
          isAddress: true,
          pl: "0x...",
          hint: "Address that submitted the contract",
          gr: "id",
          locked: true,
          hidden: true,
          optional: true
        },
        addr: {
          label: "",
          gr: "addr",
          // array: EVMAddressDefinition(instance._proxy)
          array: () =>
            instance._proxy.new({
              label: "Address",
              name: "EVMAddress",
              min: 1
            })
        },
        desc: {
          label: "Description",
          hint: "A description of your contract",
          pl: "A description of your contract...",
          base: "string",
          long: true,
          gr: "id",
          optional: true,
          icon: "bars-left-o"
        },
        src: {
          label: "Source code in Solidity URL",
          base: "string",
          pl: "https://...",
          pattern: defaultPatterns.url,
          gr: "add",
          optional: true,
          icon: "code-bracket-o"
        },
        doc: {
          label: "URI of the official doc",
          base: "string",
          pl: "https://...",
          pattern: defaultPatterns.url,
          gr: "add",
          optional: true,
          icon: "book-open-o"
        },
        tok: {
          label: "Token query",
          hint: "The token query if the contract is a token",
          pl: "tok:xxxx/xxx",
          base: "string",
          gr: "add",
          hidden: !is_admin,
          optional: true,
          icon: "external"
        },
        xr: {
          label: "ABI Metadata",
          gr: "add",
          array: () =>
            instance._proxy.new({
              label: "ABIExtra Reference",
              base: "string",
              search: "abix"
            }),
          // sort: true,
          hidden: !is_admin,
          optional: true,
          icon: "queue-list-o"
        }
      },
      "Smart Contract"
    );
