const DEV = false;

import {
  WrappedCell,
  type SheetProxy,
  type ValueCell
} from "@okcontract/cells";
import type { DataError, Definitions } from "@okcontract/coredata";
import {
  defaultCachedData,
  type CacheQuery,
  type CacheQueryFromType,
  type CachedData,
  type DataCacheType,
  type TypeFromCacheQuery,
  type WritableDataType
} from "@okcontract/coredata";
import { ExpiryTracker } from "@okcontract/expiry";
import { TimeSync } from "@scv/auth";

import type { APICacheInterface } from "./api";
import { verifySignature } from "./signers";

export type Collector<Query> = (qs: Query[]) => Promise<unknown[]>;

/**
 * DataNever is a data that will never be available (either non-existent or
 * permanent error).
 */
export type DataNever<Q extends CacheQuery> = {
  q: Q;
  never: boolean;
  isError?: boolean;
  errMsg?: string;
};

/**
 * DataWait is the data returned while waiting for a data to be available
 * (e.g. generated by a datacache Provider).
 */
export type DataWait<Q extends CacheQuery> = { q: Q; wait: boolean };

/**
 * OKData is a valid data returned by OKcontract node.
 */
export type OKData<Q extends CacheQuery> = CachedData<
  TypeFromCacheQuery<Q>
>["data"];

// @todo use server-generated type definitions
export type DataOf<Q extends CacheQuery> =
  | OKData<Q>
  /** data will never be a available (permanently missing or error) */
  | DataNever<Q>
  /** waiting for data */
  | DataWait<Q>;

export type PromiseOfData<Q extends CacheQuery> = (
  data: DataOf<Q> | PromiseLike<DataOf<Q>>
) => void;

type Query = CacheQuery;

// /**
//  * QueryCacheInterface defines the interface for a QueryCache.
//  */
// export interface QueryCacheInterface {
//   // _collect: (qs: Query[]) => Promise<void>;
//   _refresh: (qs: Query[]) => Promise<void>;
//   cell: <Q extends Query>(q: Q, proxy?: SheetProxy) => WrappedCell<DataOf<Q>>;
//   activate: (q: Query, status?: boolean) => void;
//   _now(): Promise<number>;
//   hasValid: (q: Query, now: number) => boolean;
//   _notify: <Q extends Query>(q: Q, resolve: PromiseOfData<Q>) => void;

//   readonly _expiry: ExpiryTracker<Query>;
// }

// @todo move to utils
function difference<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  return new Set(Array.from(setA).filter((elem) => !setB.has(elem)));
}

/**
 * QueryCache reimplements a cache with a cell for each Query.
 * @todo cells with multiple simultaneous update
 * @todo split Data that should be alone in cell, and put other
 * data in other cache.
 *
 * @todo use collect when appropriate
 * @todo extract promise to separate class, use `cellPromise`
 */
export class QueryCache {
  // implements QueryCacheInterface
  // @todo move to separate Class
  /** loop delay in ms */
  private _loopDelay: number;
  private _timeout: number; // @todo Node support | NodeJS.Timeout;
  // browser / node env
  private _LIVE: boolean;

  // <
  // Query extends string
  //   Types extends string
  //   CacheMap extends {[key in Query]: any}
  // >
  private _cache: {
    [key in CacheQuery]: ValueCell<DataOf<key>>;
  };
  private _proxy: SheetProxy;
  readonly _expiry: ExpiryTracker<Query>;
  private _verified: Set<Query>;
  /**
   * resolvers for cells being added
   * @todo types
   */
  private _promises: Map<
    Query,
    ((data: CachedData<keyof Definitions>["data"]) => void)[]
  >;
  private _API: APICacheInterface;
  /**
   * data that should be collected after refresh
   * @todo rename ExpiryTracker
   */
  private _collection: ExpiryTracker<Query>;
  /**
   * actively tracked queries
   */
  private _active: Set<Query>;
  public readonly _types: Map<Query, DataCacheType>;
  public readonly _links: Map<Query, Query>;
  private _ts: TimeSync;

  constructor(
    proxy: SheetProxy,
    api: APICacheInterface,
    { loopDelay = 10_000, ts = new TimeSync(api._endpoint) }
  ) {
    this._cache = {} as {
      [key in CacheQuery]: ValueCell<DataOf<key>>;
    };
    this._proxy = proxy;
    this._expiry = new ExpiryTracker();
    this._verified = new Set();
    this._promises = new Map();
    this._API = api;
    this._collection = new ExpiryTracker();
    this._active = new Set();
    // launch loop
    this._loopDelay = loopDelay;
    this._LIVE = true;
    this._loop();
    this._types = new Map();
    this._links = new Map();
    this._ts = ts;
  }

  /**
   * keys (queries) in cache.
   */
  get keys() {
    return Object.keys(this._cache) as CacheQuery[];
  }

  /**
   * retrieves or create a cell.
   * @param q
   * @returns
   */
  public cell = <Q extends Query>(
    q: Q,
    proxy: SheetProxy = this._proxy
  ): WrappedCell<DataOf<Q>> => {
    if (this._cache[q]) {
      // console.log({ newWrapped: q });
      return new WrappedCell(this._cache[q], proxy);
    }
    const pr = new Promise(
      (resolve: (data: CachedData<keyof Definitions>["data"]) => void) => {
        // Append the promise to the list
        this._notify(q, resolve);
      }
    );
    // console.log({ newCell: q });
    // @ts-expect-error complex?
    this._cache[q] = this._proxy.new(pr, q);
    return new WrappedCell(this._cache[q], proxy);
  };

  public activate = (q: Query, status = true) => {
    if (status) this._active.add(q);
    else this._active.delete(q);
  };

  isVerified(q: Query) {
    return this._verified.has(q);
  }

  /**
   * _add_update adds or updates a Cell for a CachedData.
   * @param cd
   * @returns
   * @todo only use cell for new cell creation?
   */
  _add_update = async <T extends DataCacheType>(
    cd: CachedData<T>
  ): Promise<void> => {
    // If it is a link, we keep the original query for which the promise
    // registration was done.
    const query = cd?.lnk || cd.q;
    const previous = this._cache[query];

    // update expiry
    this._expiry.delete(query);
    if (cd.exp) this._expiry.setExpiry(query, cd.exp * 1000); // don't forget to convert to ms

    // update signature verification
    this._verified.delete(query);
    if (cd.sig) {
      const verified = await verifySignature(cd);
      if (verified) this._verified.add(query);
    }
    this._types.set(query, cd.ty);
    if (cd.ty === "error") {
    }

    // call promises, notifications
    const pr = this._promises.get(query);
    this._promises.delete(query);
    if (pr?.length) {
      // Resolve all promises
      for (const p of pr) p(cd.data);
    }

    // update Cell if it already exists. It should always
    // exist. If it was newly created by cell(...)
    // the previous promise has just filled it, so the following
    // call to `set` is superfluous (but has no effect because of isEqual).
    if (previous) {
      // console.log({ update: query, keys: Object.keys(this._cache) });
      // @ts-expect-error improve types
      this._cache[query].set(cd.data);
    }
    // If this was link, keep a copy to save next update.
    if (cd?.lnk) {
      this._links.set(cd?.lnk, cd.q);
      // @ts-expect-error improve types
      this._cache[cd?.lnk].set(cd.data);
    }
  };

  _wait_never(cd: DataWait<Query> | DataNever<Query>) {
    const pr = this._promises.get(cd.q);
    this._promises.delete(cd.q);
    if (pr?.length) {
      // Resolve all promises
      for (const p of pr) p(cd);
    }
    // @ts-expect-error improve types
    this._cache[cd.q]?.set(cd);
  }

  /**
   * Notify to a promise when a given q is available.
   * @param resolve promise
   * @todo handle rejections?
   */
  public _notify = <Q extends Query>(q: Q, resolve: PromiseOfData<Q>) => {
    DEV && console.log({ at: Date.now(), notify: q });
    const l = this._promises.get(q) || [];
    // @ts-ignore @todo check types
    this._promises.set(q, [...l, resolve]);
  };

  // @todo separate type for each element
  private _add_update_data_list = async (
    data: CachedData<keyof Definitions>[]
  ) => {
    data.forEach(this._add_update);
  };

  /**
   * starts the loop.
   * @param delay in sec
   */
  _loop = async () => {
    if (this._LIVE) {
      const hasExpired = await this._expiry.hasExpiredKeys();
      DEV &&
        console.log({
          cache: "data",
          at: Date.now(),
          loop: "start",
          hasExpired,
          next: `${this._loopDelay}ms`
        });
      if (hasExpired) this._refresh([]);
    }
    this._timeout = window.setTimeout(this._loop, this._loopDelay);
  };

  setLive = (live: boolean) => {
    this._LIVE = live;
  };

  /**
   * cancel the loop
   */
  _stop = () => {
    clearTimeout(this._timeout);
  };

  /**
   * collection
   * @param qs
   * @todo do we force collection even if we have a permanent data in cache?
   */
  _collect = async (qs: Query[]) => {
    try {
      const r = await this._API.Collect(qs as Query[]);
      DEV && console.log("CACHE:collect", r);
      this._add_update_data_list(r.cd);
    } catch (error) {
      DEV && console.log("⚠️", "collect failed", qs);
    }
  };

  /**
   * hasValid checks if the query data is available and still valid in cache.
   * @param q
   * @param at
   * @returns
   */
  hasValid(q: Query, now: number) {
    const v = this._cache[q]?.value;
    if (
      v === undefined ||
      v === null ||
      (typeof v === "object" &&
        (("wait" in v && v?.wait) || ("never" in v && v?.never)))
    )
      return false;
    const exp = this._expiry.find(q);
    DEV && console.log({ at: Date.now(), q, exp, now, valid: exp >= now });
    return !exp || exp >= now;
  }

  // @todo timesync in class parameter?
  async _now() {
    const ts = await this._ts.Get();
    return ts.real.getTime();
  }

  /**
   * _unavailable returns all queries that are either missing or outdated.
   * @param qs
   * @returns iterator on queries that are not available
   */
  async _unavailable(qs: Query[]) {
    if (!qs?.length) return [];
    // DEV && console.log({ at: Date.now(), ts: "starting", qs });
    const now = await this._now();
    // DEV && console.log({ at: Date.now(), ts: "done", now });
    return qs.filter((q) => !this.hasValid(q, now));
  }

  /**
   * low-level function that takes a list as argument
   * and refreshes the whole list.
   * @param qs list of queries
   * @returns
   */
  _refresh = async (qs: Query[]): Promise<void> => {
    DEV && console.log({ cache: "data", at: Date.now(), refresh: qs });
    // We should never ask for data that we already have (non-expired)
    // and we should always add the expired data.
    const requested = [
      ...new Set(
        [
          ...(await this._unavailable(qs)),
          ...(await this._expiry.takeExpiredKeys())
        ].filter((q) => this._active.has(q))
      )
    ];
    if (!requested?.length) {
      DEV && console.log({ cache: "data", at: Date.now(), refresh: "skip" });
      return;
    }
    try {
      // @todo the API should return the empty Object?
      const { time, cd, miss } =
        (await this._API.Refresh(requested, true)) || {};
      DEV && console.log({ at: Date.now(), refresh: qs, requested, time, cd });
      const returned = new Set(cd?.map((v) => v.q));
      const allMissing = difference(new Set(requested), returned);
      const def = defaultCachedData([...allMissing]);
      const defSet = new Set(def.map((cd) => cd.q));
      // will never be refreshed
      const never = miss?.filter((q) => !defSet.has(q)) || [];
      DEV && console.log({ def, never, miss, allMissing });
      // remove default values from missing set
      for (const cd of def) {
        allMissing.delete(cd.q);
      }
      const wait = [...allMissing].filter((q) => !never.includes(q));
      // console.log({ cd, miss, wait });
      const now = await this._now();
      const expired =
        cd?.filter((v) => v.exp && v.exp < now).map((v) => v.q) || [];
      // update items to collect later
      for (const v of [...allMissing, ...expired]) {
        this._collection.setExpiry(v, time * 1000);
      }
      const errors = cd?.filter((cd) => cd.ty === "error") || [];
      const valid = cd?.filter((cd) => cd.ty !== "error") || [];
      // update cache with partial results and default values
      this._add_update_data_list([...(valid || []), ...def]);
      for (const q of never) this._wait_never({ never: true, q });
      for (const cd of errors)
        this._wait_never({
          never: true,
          q: cd.q,
          isError: true,
          errMsg: (cd.data as DataError)?.e
        });
      for (const q of wait) this._wait_never({ wait: true, q });
    } catch (error) {
      DEV && console.log("⚠️", "refresh failed", qs, error);
    }
  };

  /**
   * Write a Data both from the node and locally.
   * @param ty
   * @param msg
   * @param sig
   * @param auth
   * @returns
   */
  write = async <T extends WritableDataType>(
    ty: T,
    msg: string,
    sig: string,
    auth?: string
  ) => {
    const out = await this._API.Write<T>(msg, sig, auth);
    if (!out?.cd?.length) throw new Error(`${ty} not saved, please try again.`);
    const cd = out.cd[0];
    // We don't wait here, the cells will be updated later.
    this._add_update(cd);
    return cd;
  };

  /**
   * Delete a query both from the node and locally.
   * @param ty
   * @param q
   * @param sig
   * @returns
   * @todo Do we ensure that we have the local data first?
   */
  delete = async <T extends WritableDataType>(
    ty: T,
    q: CacheQueryFromType<T>,
    sig: string
  ) => {
    const out = await this._API.Delete(q, sig);
    if (!out?.cd?.length)
      throw new Error(`${ty} not deleted, please try again.`);
    const cd = out.cd[0];
    // For mapped cells, we set to null.
    // @todo delete?
    const cell = this._cache[cd.q];
    cell.set(null);
    // this._cache[cd.q].delete();
    // delete this._cache[cd.q];
    this._expiry.delete(cd.q);
    this._verified.delete(cd.q);
    return cd;
  };

  public NewID = () => {
    return this._API.NewID();
  };
}
