import { decodeEventLog, parseAbi } from "viem";

import { type AnyCell, type CellArray, mapArray } from "@okcontract/cells";
import type { LogEntry } from "@okcontract/multichain";
import {
  type ChainType,
  type EVMAddress,
  Address,
  ContractType
} from "@okcontract/multichain";

import { type TokenContractData, ERC20 } from "./erc20";
import { ERC721 } from "./erc721";
import type { Instance } from "./instance";
import { logAbi } from "./log";
import { searchAddress } from "./search";

export type AnalyzedLog = {
  address: Address;
  event: string;
  eventArgs: { [key: string]: unknown };
  tcd?: TokenContractData;
  nftcd?: boolean;
};

export const analyzeLog = (
  instance: Instance,
  log: AnyCell<LogEntry>,
  ch: AnyCell<ChainType>
): AnyCell<AnalyzedLog> => {
  const proxy = instance._proxy;

  const addr = proxy.map(
    [log, ch],
    (_log, _ch) =>
      ({
        /**
         * @security we trust the address from the log
         */
        addr: new Address(_log?.address),
        chain: _ch,
        ty: ContractType
      }) as EVMAddress,
    "analyzeLog.addr"
  );
  const searched = addr.map(
    (_addr) => searchAddress(instance._core, _addr),
    "analyze.searched"
  );
  const realAddr = proxy.map(
    [searched, ch],
    (_searched, _ch) =>
      Array.isArray(_searched.addr)
        ? (_searched.addr as EVMAddress[]).find((addr) => addr.chain === _ch)
        : (_searched.addr as EVMAddress),
    "analyze.realAddr"
  );

  const abi = instance._getABI(realAddr);
  const parsedAbi = abi.map((_abi) => _abi.parsed);

  // @todo only get when abi is erc20
  // atm issue with pointers ?
  const decimals = instance._getDecimals(addr);
  const symbol = instance._getSymbol(addr);
  const extraData = proxy.map(
    [abi, decimals, symbol],
    (_abi, _decimals, _symbol) =>
      Array.isArray(_abi.erc)
        ? _abi?.erc.includes(ERC721)
          ? { nftcd: true }
          : _abi?.erc.includes(ERC20)
            ? {
                tcd: {
                  decimals,
                  symbol
                }
              }
            : {}
        : {},
    "analyze.extraData"
  );
  const logData = log.map(
    (_log) => ({
      topics: "topics" in _log && _log.topics,
      data: _log.data
    }),
    "analyzeLog.logData"
  );
  const parsedLog = proxy.map(
    [parsedAbi, logData],
    (_abi, _logData) => {
      try {
        // try to decode logs based on given ABI
        const decoded = decodeEventLog({
          abi: _abi,
          topics: _logData.topics as
            | []
            | [signature: `0x${string}`, ...args: `0x${string}`[]],
          data: _logData.data as `0x${string}`
        });
        return decoded;
      } catch (error) {
        // try to decode logs based with our log ABI
        const decoded = decodeEventLog({
          abi: parseAbi(logAbi),
          topics: _logData.topics as
            | []
            | [signature: `0x${string}`, ...args: `0x${string}`[]],
          data: _logData.data as `0x${string}`
        });
        return decoded;
      }
    },
    "analyzeLog.parsedLog"
  );

  const eventArgs = proxy.map([abi, parsedLog], (_abi, _parsedLog) => {
    return Object.entries(_parsedLog?.args).reduce(
      (acc, [k, v], idx) => {
        // retrieved name of args following ABI
        const log = _abi.parsed.find(
          (_abi) => "name" in _abi && _abi.name === _parsedLog?.eventName
        );
        const argName =
          (log && "inputs" in log && log.inputs?.[idx])?.name || k;

        acc[argName] = v;
        acc[`${idx}`] = v;
        return acc;
      },
      {} as { [key: string]: unknown }
    );
  });

  return proxy.map(
    [log, parsedLog, eventArgs, extraData],
    async (_log, _parsedLog, _eventArgs, _extraData) =>
      ({
        address: new Address(_log.address),
        event: _parsedLog?.eventName,
        eventArgs: _eventArgs,
        ..._extraData
      }) as AnalyzedLog,
    "analyzeLog.res"
  );
};

export const analyzeLogs = (
  instance: Instance,
  chain: AnyCell<ChainType>,
  logsCell: CellArray<LogEntry>
) =>
  mapArray(
    instance._proxy,
    logsCell,
    (_log, _idx, cell) => instance.analyzeLog(cell, chain),
    "analyzeLogs"
  );
