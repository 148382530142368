<script lang="ts">
  import type { Stepper } from "@okcontract/sdk";
  import { newInstance } from "@okcontract/svelte-sdk";
  import { AlertBanner, ThemeText, getTheme } from "@okcontract/uic";
  import { Image, Success } from "@scv/dcuic";

  import OrgSnippet from "@scv/dcuic/OrgSnippet.svelte";
  import ABIFunction from "./ABIFunction.svelte";
  import Titles from "./Titles.svelte";
  import WidgetHeader from "./WidgetHeader.svelte";
  import WidgetTxSent from "./WidgetTXSent.svelte";

  export let instance = newInstance("WidgetTX");
  export let stepper: Stepper;
  export let hideLabels = true;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const okTX = stepper.current;
  const isDone = stepper.isDone;
  const isLastTX = stepper.isLastTX;
  const sentTxs = stepper.sentTXs;
  const receipts = stepper.receipts;

  $: image = instance._proxy.map([okTX], (_s) => _s.image, "WidgetTX.image");
  $: org = instance._proxy.map([okTX], (_s) => _s.org, "WidgetTX.org");
</script>

{#if $okTX instanceof Error}
  <div class="p-4">
    <AlertBanner style="warning"
      ><span class="max-w-[265px]"
        ><strong
          >Error in interaction specification:
        </strong>{$okTX.message}</span
      ></AlertBanner
    >
  </div>
{:else if $okTX}
  <WidgetHeader {instance} {theme} okTX={$okTX} />
  <div
    class="absolute bottom-12 left-0 right-0 top-12 overflow-y-auto overscroll-y-contain {theme.dark(
      $compiledTheme instanceof Error ? null : $compiledTheme,
      'text-white',
      'text-black',
      'text-base-content'
    )}"
    style={theme.apply(
      $compiledTheme instanceof Error ? null : $compiledTheme,
      [ThemeText]
    )}
  >
    <!-- @todo receipts should be on each chain... -->

    {#if $isDone && $okTX.receipt}
      <div class="px-4 py-2 capitalize">
        <OrgSnippet {instance} {theme} q={`org:${$org}`} small={false} />
      </div>
      <div class="px-4 mt-4">
        <Success message={"Transaction successful"} />
        <div class="px-4 py-2">
          <WidgetTxSent
            txs={sentTxs}
            receipt={$okTX.receipt}
            {receipts}
            chain={$okTX.chain}
            tokenPrice={$okTX.tokenPriceData}
          />
        </div>
      </div>
    {:else if $isLastTX}
      <div class="px-4 py-2 capitalize">
        <OrgSnippet {instance} {theme} q={`org:${$org}`} small={false} />
      </div>
      <div class="px-4 mt-4">
        <div class="my-4 flex w-full justify-center">
          <span class="loading loading-spinner loading-lg"></span>
        </div>
        <h4 class="flex w-full justify-center">
          Transaction in progress. Please wait...
        </h4>
      </div>
    {:else}
      {#if $image && !($image instanceof Error)}
        <div class="mx-auto my-2 w-56">
          <div
            class="flex h-56 w-56 items-center overflow-hidden rounded-md
              {theme.dark(
              $compiledTheme instanceof Error ? null : $compiledTheme,
              'bg-white-alpha text-white',
              'bg-black-alpha text-black',
              'bg-base-200 text-base-content'
            )}"
          >
            <Image src={$image} />
          </div>
        </div>
      {/if}
      <div class="px-4 py-2 capitalize">
        <OrgSnippet {instance} {theme} q={`org:${$org}`} small={false} />
      </div>
      <div class="px-4 text-left">
        <div
          class="py-2 flex gap-1 flex-wrap items-center text-base font-semibold"
        >
          <Titles
            {instance}
            item={$okTX.title}
            size="md"
            chain={$okTX.contractChain}
          />
        </div>
        <ABIFunction {instance} okTX={$okTX} {hideLabels} />
      </div>
    {/if}
  </div>
{/if}
