import type { AbiFunction, AbiParameter } from "abitype";

import {
  NameTuple,
  newTypeConst,
  newTypeObject,
  newTypeTuple,
  newTypeVar,
  typeBytes,
  typeFunction,
  typeList,
  typeNumber,
  type MonoType
} from "@okcontract/lambdascript";

const constantAbiType = {
  address: "address",
  bool: "boolean",
  string: "string"
};

export function abiParameterToMonoType(abiParam: AbiParameter): MonoType {
  if (abiParam.type.endsWith("]")) {
    const elementType = abiParam.type.slice(0, abiParam.type.indexOf("["));
    // @todo fix-length arrays/lists
    return typeList(
      abiParameterToMonoType({
        ...abiParam,
        // @todo use abiParam.components?
        type: elementType
      })
    );
  }

  // Handling tuples
  if (abiParam.type.startsWith("tuple")) {
    // @ts-expect-error components exist for tuple
    return newTypeTuple(abiParam?.components?.map(abiParameterToMonoType));
  }

  // Handling structs
  if (abiParam?.internalType?.startsWith("struct")) {
    const fields = {};
    // @ts-expect-error components exist for struct
    for (const component of abiParam?.components || []) {
      fields[component.name] = abiParameterToMonoType(component);
    }
    return newTypeObject(fields);
  }

  if (abiParam.type.startsWith("int") || abiParam.type.startsWith("uint"))
    // @todo min, max
    // or bits: parseInt(abiParam.type.substring(3 or 4)),
    //    signed: abiParam.type.startsWith("u") ? false : true,
    return typeNumber;

  if (abiParam.type.startsWith("bytes")) return typeBytes;

  switch (abiParam.type) {
    case "address":
    case "bool":
    case "string":
      return newTypeConst(constantAbiType[abiParam.type]);
    // Handle arrays (both fixed and dynamic)
    default:
      throw new Error(`Unsupported ABI type ${abiParam}`);
  }
}

/**
 * abiFunctionToMonoType converts an AbiFunction to a λs MonoType.
 * @param fn
 * @returns λs MonoType
 */
export const abiFunctionToMonoType = (fn: AbiFunction): MonoType =>
  typeFunction(
    fn.inputs.map(abiParameterToMonoType),
    fn.outputs?.length === 1
      ? abiParameterToMonoType(fn.outputs?.[0])
      : fn.outputs.length > 1
        ? newTypeObject(
            Object.fromEntries(
              fn.outputs.map((p) => [p.name, abiParameterToMonoType(p)])
            )
          )
        : newTypeVar("never")
  );
